import { useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
} from "react-native";
// Firebase
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
// Helpers
import Screen from "../helpers/Screen";

const LoginScreen = () => {
  // State Variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Referenzen
  const passInput = useRef(null);

  const handleLogIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        console.log("ALTERRRRR Willkommen zurück!", user.email);
      })
      .catch((err) => {
        setError("Ups❌ Email Adresse oder Passwort falsch.");
      });
  };

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View style={{ alignItems: "center" }}>
        <Text style={{ marginBottom: 25, fontSize: 24, fontWeight: "600" }}>
          Masters Login
        </Text>
        <TextInput
          placeholder="E-Mail"
          placeholderTextColor={"rgba(70, 70, 70, 0.6)"}
          value={email}
          keyboardType="email-address"
          inputMode="email"
          autoComplete="email"
          returnKeyType="next"
          onChangeText={(text) => setEmail(text)}
          autoCorrect={false}
          autoCapitalize="none"
          onSubmitEditing={() => {
            passInput.current.focus();
          }}
          style={[
            styles.input,
            {
              borderBottomColor: error ? "#ff0000a0" : "rgba(70, 70, 70, 0.3)",
              fontWeight: "500",
            },
          ]}
        />
        <TextInput
          placeholder="Passwort"
          ref={passInput}
          placeholderTextColor={"rgba(70, 70, 70, 0.6)"}
          value={password}
          keyboardType="default"
          autoComplete="password"
          returnKeyType="done"
          onChangeText={(text) => setPassword(text)}
          secureTextEntry
          onSubmitEditing={() => {
            handleLogIn();
          }}
          style={[
            styles.input,
            {
              borderBottomColor: error ? "#ff0000a0" : "rgba(70, 70, 70, 0.3)",
              fontWeight: "500",
            },
          ]}
        />
        <TouchableOpacity
          onPress={handleLogIn}
          style={{
            width: Screen.width / 1.25,
            marginTop: 30,
            backgroundColor: "#008F81",
            height: 50,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 100,
          }}
        >
          <Text style={{ color: "#fff", fontSize: 18, fontWeight: "600" }}>
            Anmelden
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },

  input: {
    width: Screen.width / 1.2,
    paddingVertical: 10,
    marginTop: 15,
    borderBottomWidth: 1.1,
    fontWeight: "400",
  },
});
