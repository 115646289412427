import { StyleSheet, Platform } from "react-native";
import Screen from "../../helpers/Screen";

export const cardStyles = StyleSheet.create({
  stampCardView: {
    width: Screen.width / 1.1,
    height: Screen.width / 1.7,
    justifyContent: "flex-end",
    marginBottom: 15,
  },
  stampCardBackView: {
    width: Screen.width / 1.1,
    height: Screen.width / 1.7,
    marginBottom: 15,
  },
  fullCardView: {
    width: Screen.width / 1.1,
    height: Screen.width / 1.7,
    marginBottom: 15,
  },
  shadow: {
    shadowColor: "#00000050",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.9,
    shadowRadius: 5,
    elevation: 50,
  },
  stampCardViewDetails: {
    borderBottomStartRadius: 15,
    borderBottomEndRadius: 15,
    backgroundColor: "rgba(1000, 1000, 1000, 0.97)",
    height: "75%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  stampImage: { height: 40, width: 40, position: "absolute", zIndex: 1 },
  stampBack: {
    height: 40,
    width: 40,
    position: "absolute",
    zIndex: 0,
  },
  stampSize: { height: 40, width: 40 },
  cardContainer: {
    width: Screen.width / 1.1,
    height: Screen.width / 1.7,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    backgroundColor: "rgba(33, 33, 33, 0.3)",
    borderRadius: 15,
  },
  stampitLogo: {
    width: Screen.width / 2.5,
    height: Screen.width / 4,
    opacity: 0.4,
  },
  stampBodyContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 20,
    paddingLeft: 12.5,
    marginTop: 10,
  },
  favicon: { width: 46, height: 46, borderRadius: 5 },
  infoContainer: { justifyContent: "center", marginLeft: 10 },
  stampAreaContainer: {
    flexDirection: "row",
    height: 50,
    width: "100%",
    justifyContent: "space-evenly",
  },
  infoText: { color: "#3a3a3a", fontSize: 13 },
  levelContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    width: 21,
    height: 22,
    position: "absolute",
    left: 18,
    bottom: -5,
  },
  levelText: {
    position: "absolute",
    color: "#fff",
    fontSize: 15,
    fontWeight: "600",
  },
  fullCardHeader: {
    backgroundColor: "#fff",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    height: Screen.width / 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fullCardFavicon: {
    width: 43,
    height: 43,
    borderRadius: 5,
    marginRight: 15,
  },
  fullCardSellerName: {
    fontSize: 16,
    /*     fontFamily: "AllroundDemi", */
    color: "#4a4a4a",
    marginRight: 5,
  },
  openingStatus: {
    fontSize: 16,
    /* fontFamily: "Worksans", */
    marginRight: 15,
    bottom: 1,
  },
  fullCardContainer: {
    flex: 1,
    backgroundColor: "rgba(1000, 1000, 1000, 0.88)",
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  fullCardBodyContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "85%",
    position: "absolute",
  },
  congratulationsText: {
    fontSize: 19,
    /*     fontFamily: "AllroundDemi", */
    color: "#DF2839",
  },
  cardFullText: {
    color: "#4a4a4a",
    fontSize: 16,
    /* fontFamily: "Worksans", */
  },
  redeemDealText: {
    color: "#4a4a4a",
    fontSize: 16,
    /* fontFamily: "Worksans", */
    marginTop: 3,
  },
  redeemDealButton: {
    width: Screen.width / 2,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 50,
  },
  redeemDealButtonText: {
    marginHorizontal: 10,
    color: "#fff",
    fontSize: 18,
    /*     fontFamily: "AllroundMedium", */
  },
  showAllDealsButton: {
    width: Screen.width / 6,
    height: Screen.width / 16,
    borderRadius: Screen.width / 16 / 3,
    alignItems: "center",
    justifyContent: "center",
  },

  showAllDealsButtonText: {
    fontSize: 16,
    color: "#fff",
  },
});
