import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  Image,
  TextInput,
  ScrollView,
  StyleSheet,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
// Firebase
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  getDocs,
  query,
  orderBy,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
// Helpers
import Screen, { safeArea } from "../helpers/Screen";
import { colors } from "../helpers/colors";

const EditSeller = () => {
  // Constants
  const width = Screen.width;

  // View State
  const [viewState, setViewState] = useState(0);

  // State Variables
  const [allSellers, setAllSellers] = useState([]);
  const [sellerToEdit, setSellerToEdit] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  var itemToEditPosition = {
    catIndex: 0,
    itemIndex: 0,
  };
  const [itemToAdd, setItemToAdd] = useState({
    name: "Beispiel Item",
    desc: "Beschreibung dies das jenes",
    price: 1,
  });
  var addItemToCat = 0;
  const [sellerAnalytics, setSellerAnalytics] = useState(null);
  const [sellerDeals, setSellerDeals] = useState(null);
  const [dealToEdit, setDealToEdit] = useState(null);
  const [dealToAdd, setDealToAdd] = useState({
    title: "",
    description: "",
    value: "",
    level: 1,
    type: "",
  });
  const [stampcards, setStampcards] = useState(null);
  const [stampcardToEdit, setStampcardToEdit] = useState();

  // Functions
  useEffect(
    () =>
      onSnapshot(collection(db, "seller"), (snapshot) => {
        setAllSellers(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }),
    []
  );

  // get Analytics
  const getAnalytics = async (sellerID) => {
    try {
      const analyticsRef = doc(db, "seller", sellerID, "analytics", "basic");

      const snapshot = await getDoc(analyticsRef);
      if (snapshot.exists()) {
        console.log("Analytics gefunden:");
        console.log(snapshot.data());
        setSellerAnalytics(snapshot.data());
      } else {
        console.log("Keine Analytics vorhanden!");
      }
    } catch (error) {
      console.error("Fehler beim holen der Analytics:", error);
    }
  };

  // get deals
  useEffect(() => {
    if (sellerToEdit) {
      const queryRef = query(
        collection(db, "seller", sellerToEdit.id, "deals"),
        orderBy("level", "asc")
      );
      const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSellerDeals(documents);
      });
      return unsubscribe;
    }
  }, [sellerToEdit]);

  // get stampcards
  const getStampcards = async () => {
    try {
      const queryRef = query(
        collection(db, "stampcard"),
        orderBy("name", "asc")
      );
      const querySnapshot = await getDocs(queryRef);
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setStampcards(documents);
    } catch (error) {
      console.log(error);
    }
  };

  // --> Delete
  const deleteCategory = (index) => {
    const newArray = [...sellerToEdit.categories];
    newArray.splice(index, 1);
    setSellerToEdit(...sellerToEdit.categories, newArray);
  };

  const deleteCategoryItem = (catIndex, index) => {
    const categoriesArr = [...sellerToEdit.categories];
    const newItemsArray = [...sellerToEdit.categories[catIndex].items];
    newItemsArray.splice(index, 1);
    categoriesArr[catIndex].items = newItemsArray;
    setSellerToEdit({ ...sellerToEdit, categories: categoriesArr });
  };

  const deleteDeal = async (dealID) => {
    try {
      const documentRef = doc(
        collection(db, "seller", sellerToEdit.id, "deals"),
        dealID
      );
      await deleteDoc(documentRef);
      console.log("Deal erfolgreich gelöscht!");
    } catch (error) {
      console.log("Deal konnte nicht gelöscht werden!" + error);
    }
  };

  // -- Edit
  const updateSeller = async () => {
    try {
      const docRef = doc(db, "seller", sellerToEdit.id);
      await updateDoc(docRef, {
        name: sellerToEdit.name,
        adress: sellerToEdit.adress,
        phone: sellerToEdit.phone,
        instagram: sellerToEdit.instagram,
        color: sellerToEdit.color,
      });
      console.log("Dokument erfolgreich aktualisiert!");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Dokuments:", error);
    }
  };

  const editItem = () => {
    const updatedItem = {
      name: itemToEdit.name,
      desc: itemToEdit.desc,
      price: Number(itemToEdit.price),
    };
    if (itemToEdit.image !== "") {
      updatedItem.image = itemToEdit.image;
    }
    if (itemToEdit.duration !== "") {
      updatedItem.duration = itemToEdit.duration;
    }
    if (itemToEdit.variations) {
      updatedItem.variations = itemToEdit.variations;
    }
    const categoriesArr = [...sellerToEdit.categories];
    const newItemsArray = [
      ...sellerToEdit.categories[itemToEditPosition.catIndex].items,
    ];
    newItemsArray[itemToEditPosition.itemIndex] = updatedItem;
    categoriesArr[itemToEditPosition.catIndex].items = newItemsArray;
    setSellerToEdit({ ...sellerToEdit, categories: categoriesArr });
    setViewState(3);
  };

  const updateDeal = async () => {
    const updatedDeal = {
      title: dealToEdit.title,
      description: dealToEdit.description,
      value: Number(dealToEdit.value),
      level: Number(dealToEdit.level),
      type: dealToEdit.type,
    };
    try {
      const docRef = doc(db, "seller", sellerToEdit.id, "deals", dealToEdit.id);
      await updateDoc(docRef, updatedDeal).then(setViewState(4));
    } catch (error) {
      console.log(error);
    }
  };

  const updateSellerStampcard = async () => {
    const updatedSellerStampcard = {
      stampcards: [stampcardToEdit],
    };
    console.log(stampcardToEdit);
    try {
      const docRef = doc(db, "seller", sellerToEdit.id);
      await updateDoc(docRef, updatedSellerStampcard).then(setViewState(1));
    } catch (error) {
      console.log(error);
    }
  };

  const addItem = () => {
    if (itemToAdd.name && itemToAdd.desc && itemToAdd.price !== "") {
      if (!isNaN(itemToAdd.price)) {
        const newItem = {
          name: itemToAdd.name,
          desc: itemToAdd.desc,
          price: Number(itemToAdd.price),
        };
        if (itemToAdd.image !== "") {
          newItem.image = itemToAdd.image;
        }
        if (itemToAdd.duration !== "") {
          newItem.duration = itemToAdd.duration;
        }

        const categoriesArr = [...sellerToEdit.categories];
        categoriesArr[addItemToCat].items.push(newItem);
        setSellerToEdit({ ...sellerToEdit, categories: categoriesArr });
        setViewState(3);
      } else {
        console.log("price is not a number ");
      }
    }
  };

  const addDeal = async () => {
    if (
      dealToAdd.title &&
      dealToAdd.type &&
      dealToAdd.value &&
      dealToAdd.level !== ""
    ) {
      const deal = {
        title: dealToAdd.title,
        description: dealToAdd.description,
        value: Number(dealToAdd.value),
        level: Number(dealToAdd.level),
        type: dealToAdd.type,
      };
      try {
        const collectionRef = collection(
          db,
          "seller",
          sellerToEdit.id,
          "deals"
        );
        await addDoc(collectionRef, deal).then(setViewState(4));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <SafeAreaView
      style={[
        safeArea.AndroidSafeArea,
        { flex: 1, alignItems: "center", justifyContent: "center" },
      ]}
    >
      {/* View for showing all sellers */}
      {viewState === 0 && (
        <ScrollView style={{ width: width / 1.1 }}>
          <Text style={{ marginTop: 15, fontSize: 22, fontWeight: "600" }}>
            Seller bearbeiten
          </Text>
          {allSellers && (
            <View style={styles.container}>
              {allSellers.map((seller) => {
                return (
                  <TouchableOpacity
                    key={seller.id}
                    style={{
                      width: "100%",
                      height: 55,
                      backgroundColor: seller.color,
                      marginBottom: 15,
                      borderRadius: 30,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onPress={() => {
                      setSellerToEdit(seller);
                      setStampcardToEdit(seller.stampcards[0]);
                      console.log(seller);
                      getAnalytics(seller.id);
                      setViewState(1);
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#fff",
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        marginLeft: 2.5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        source={{ uri: seller.favicon }}
                        resizeMode="cover"
                        style={{ width: 45, height: 45, borderRadius: 25 }}
                      />
                    </View>
                    <Text
                      style={{
                        marginRight: 25,
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                    >
                      {seller.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
        </ScrollView>
      )}
      {/* View for Overview */}
      {viewState === 1 && (
        <ScrollView style={{ flex: 1, width: Screen.width }}>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              {sellerToEdit.name}
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(0);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          {sellerToEdit && (
            <View style={{ width: "100%", flex: 1, alignItems: "center" }}>
              <View
                style={{
                  width: "90%",
                  backgroundColor: `${sellerToEdit.color}30`,
                  marginTop: 15,
                  borderRadius: 20,
                  padding: 15,
                }}
              >
                {sellerAnalytics && (
                  <View>
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#3a3a3a",
                        marginBottom: 10,
                      }}
                    >
                      Analytics: Basic
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                        color: "#3a3a3a",
                        marginBottom: 5,
                      }}
                    >
                      Kunden: {sellerAnalytics.customersTotal}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                        color: "#3a3a3a",
                        marginBottom: 5,
                      }}
                    >
                      Stempel vergeben: {sellerAnalytics.stampsTotal}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                        color: "#3a3a3a",
                        marginBottom: 5,
                      }}
                    >
                      Deals eingelöst: {sellerAnalytics.dealsTotal}
                    </Text>
                  </View>
                )}
              </View>
              <TouchableOpacity
                style={{ width: "90%", height: 100, marginTop: 15 }}
                onPress={() => {
                  setViewState(2);
                }}
              >
                <Image
                  source={{ uri: sellerToEdit.images[0] }}
                  resizeMode="cover"
                  style={{ width: "100%", height: "100%", borderRadius: 20 }}
                />
                <View
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: 20,
                    backgroundColor: `${sellerToEdit.color}90`,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 15,
                      fontSize: 20,
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {sellerToEdit.name}
                    {"\n"}Infos bearbeiten
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setViewState(3);
                }}
                style={{
                  width: "90%",
                  height: 80,
                  marginTop: 15,
                  backgroundColor: "#444",
                  borderRadius: 20,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 20,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  Seller Menü bearbeiten
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setViewState(4);
                }}
                style={{
                  width: "90%",
                  height: 80,
                  marginTop: 15,
                  backgroundColor: "#008f81",
                  borderRadius: 20,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 20,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  Deals bearbeiten
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  getStampcards();
                  setViewState(5);
                }}
                style={{
                  width: "90%",
                  height: 80,
                  marginTop: 15,
                  backgroundColor: "#DF2839",
                  borderRadius: 20,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 20,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  Stempelkarte bearbeiten
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
      )}
      {/* View for Edit seller Infos */}
      {viewState === 2 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Infos bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(1);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          <View style={{ alignItems: "center", width: Screen.width }}>
            <TextInput
              style={{
                width: "90%",
                padding: 13,
                marginTop: 15,
                marginBottom: 6,
                borderWidth: 2,
                borderColor: "#bbbbbb",
                borderRadius: 10,
              }}
              value={sellerToEdit.name}
              placeholder="Name:"
              onChangeText={(text) =>
                setSellerToEdit({ ...sellerToEdit, name: text })
              }
              autoCorrect={false}
              autoCapitalize={"sentences"}
            />
            <TextInput
              style={{
                width: "90%",
                padding: 13,
                marginVertical: 6,
                borderWidth: 2,
                borderColor: "#bbbbbb",
                borderRadius: 10,
              }}
              value={sellerToEdit.adress}
              placeholder="Adresse:"
              onChangeText={(text) =>
                setSellerToEdit({ ...sellerToEdit, adress: text })
              }
              autoCorrect={false}
              autoCapitalize={"sentences"}
            />
            <TextInput
              style={{
                width: "90%",
                padding: 13,
                marginVertical: 6,
                borderWidth: 2,
                borderColor: "#bbbbbb",
                borderRadius: 10,
              }}
              value={sellerToEdit.phone}
              placeholder="Phone:"
              onChangeText={(text) =>
                setSellerToEdit({ ...sellerToEdit, phone: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={{
                width: "90%",
                padding: 13,
                marginVertical: 6,
                borderWidth: 2,
                borderColor: "#bbbbbb",
                borderRadius: 10,
              }}
              value={sellerToEdit.instagram}
              placeholder="Instagram:"
              onChangeText={(text) =>
                setSellerToEdit({ ...sellerToEdit, instagram: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={{
                width: "90%",
                padding: 13,
                marginVertical: 6,
                borderWidth: 2,
                borderColor: "#bbbbbb",
                borderRadius: 10,
              }}
              value={sellerToEdit.color}
              placeholder="Farbe:"
              onChangeText={(text) =>
                setSellerToEdit({ ...sellerToEdit, color: text })
              }
              autoCorrect={false}
            />
            <TouchableOpacity
              onPress={updateSeller}
              style={{
                width: "50%",
                height: 60,
                backgroundColor: colors.red,
                marginTop: 40,
                borderRadius: 40,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "#fff", fontSize: 20, fontWeight: "600" }}>
                Updaten!
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
      {/* View for Edit Menu */}
      {viewState === 3 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Menü bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(1);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          {sellerToEdit.categories && (
            <View
              style={{
                width: Screen.width,
                flex: 1,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {sellerToEdit.categories.map((category, catIndex) => {
                return (
                  <View
                    key={catIndex}
                    style={{ width: "90%", marginBottom: 20 }}
                  >
                    <Text
                      style={{
                        color: colors.green,
                        fontSize: 20,
                        fontWeight: "600",
                        marginBottom: 20,
                      }}
                    >
                      {category.name}
                    </Text>
                    {/* Delete Button */}
                    <TouchableOpacity
                      onPress={() => {
                        deleteCategory(catIndex);
                      }}
                      style={{
                        height: 25,
                        width: 25,
                        borderRadius: 20,
                        backgroundColor: colors.red,
                        position: "absolute",
                        alignItems: "center",
                        justifyContent: "center",
                        right: 50,
                      }}
                    >
                      <Image
                        source={require("../assets/icons/bin.png")}
                        resizeMode="stretch"
                        style={{
                          height: 15,
                          width: 12,
                          tintColor: "#fff",
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        addItemToCat = catIndex;
                        setViewState(7);
                      }}
                      style={{
                        width: 50,
                        height: 30,
                        flexDirection: "row",
                        alignItems: "center",
                        position: "absolute",
                        right: -15,
                        bottom: -15,
                      }}
                    >
                      <View
                        style={{
                          height: 3,
                          width: "40%",
                          backgroundColor: "#222",
                        }}
                      />
                      <View
                        style={{
                          width: "60%",
                          height: "100%",
                          borderRadius: 30,
                          backgroundColor: "#222",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text style={{ color: "#fff", fontSize: 25 }}>+</Text>
                      </View>
                    </TouchableOpacity>
                    {category.items.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setItemToEdit(item);
                            itemToEditPosition = {
                              catIndex: catIndex,
                              itemIndex: index,
                            };
                            setViewState(6);
                          }}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderRadius: 20,
                            marginBottom: 15,
                            padding: 10,
                          }}
                        >
                          {/* Delete Button */}
                          <TouchableOpacity
                            onPress={() => {
                              deleteCategoryItem(catIndex, index);
                            }}
                            style={{
                              height: 25,
                              width: 25,
                              borderRadius: 20,
                              backgroundColor: colors.red,
                              position: "absolute",
                              top: -12,
                              left: -12,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Image
                              source={require("../assets/icons/bin.png")}
                              resizeMode="stretch"
                              style={{
                                height: 15,
                                width: 12,
                                tintColor: "#fff",
                              }}
                            />
                          </TouchableOpacity>
                          <View
                            style={{
                              width: Screen.width / 2,
                            }}
                          >
                            <Text style={{ fontSize: 16, fontWeight: "500" }}>
                              {item.name}
                            </Text>
                            {item.duration && (
                              <Text style={{ color: colors.green }}>
                                {item.duration}
                              </Text>
                            )}
                            {item.desc && (
                              <Text
                                style={{ fontSize: 11, color: "#3a3a3a" }}
                                numberOfLines={2}
                              >
                                {item.desc}
                              </Text>
                            )}
                            {item.image && (
                              <Text
                                style={{
                                  color: colors.green,
                                  fontSize: 17,
                                  fontWeight: "600",
                                }}
                              >
                                {item.variations && "ab "}
                                {item.price.toFixed(2)} €
                              </Text>
                            )}
                          </View>
                          {item.image ? (
                            <Image
                              source={{ uri: item.image }}
                              resizeMode="cover"
                              style={{
                                width: Screen.width / 3.3,
                                height: 70,
                                borderRadius: 15,
                              }}
                            />
                          ) : (
                            <Text>{item.price.toFixed(2)} €</Text>
                          )}
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          )}
          {!sellerToEdit.categories && <View>gibt kein Menü</View>}
        </ScrollView>
      )}
      {/* Edit Deals */}
      {viewState === 4 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Deals bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(1);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          {sellerDeals && (
            <View
              style={{
                width: Screen.width,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {sellerDeals.map((deal, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={{
                      width: "90%",
                      padding: 15,
                      backgroundColor: `${sellerToEdit.color}bb`,
                      marginBottom: 15,
                      borderRadius: 30,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onPress={() => {
                      setDealToEdit(deal);
                      setViewState(4.5);
                    }}
                  >
                    {/* Delete Button */}
                    <TouchableOpacity
                      onPress={() => {
                        deleteDeal(deal.id);
                      }}
                      style={{
                        height: 25,
                        width: 25,
                        borderRadius: 20,
                        backgroundColor: colors.red,
                        position: "absolute",
                        alignItems: "center",
                        justifyContent: "center",
                        top: -5,
                        left: -5,
                      }}
                    >
                      <Image
                        source={require("../assets/icons/bin.png")}
                        resizeMode="stretch"
                        style={{
                          height: 15,
                          width: 12,
                          tintColor: "#fff",
                        }}
                      />
                    </TouchableOpacity>
                    <View style={{ width: "80%" }}>
                      <Text
                        style={{
                          color: "#fff",
                          fontWeight: "600",
                          fontSize: 16,
                        }}
                      >
                        {deal.title}
                      </Text>
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 10,
                        }}
                      >
                        {deal.description}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 20,
                          fontWeight: "600",
                        }}
                      >
                        LVL {deal.level}
                      </Text>
                      <Text style={{ color: "#fff", fontWeight: "700" }}>
                        {deal.type === "detailed" ? "FREE" : `${deal.value} €`}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          <TouchableOpacity
            onPress={() => {
              setViewState(4.6);
            }}
            style={{
              width: Screen.width / 1.5,
              height: 50,
              borderRadius: 25,
              backgroundColor: colors.red,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <Text style={{ fontSize: 18, color: "#fff", fontWeight: "500" }}>
              Deal hinzufügen
            </Text>
          </TouchableOpacity>
        </ScrollView>
      )}
      {/* Edit Deals second */}
      {viewState === 4.5 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Deals bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(4);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          {dealToEdit && (
            <View
              style={{
                width: Screen.width,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <View
                style={{
                  width: "90%",
                  padding: 15,
                  backgroundColor: `${sellerToEdit.color}bb`,
                  marginBottom: 15,
                  borderRadius: 100,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "80%" }}>
                  <Text
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: 16,
                    }}
                  >
                    {dealToEdit.title}
                  </Text>
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 10,
                    }}
                  >
                    {dealToEdit.description}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: "600",
                    }}
                  >
                    LVL {dealToEdit.level}
                  </Text>
                  <Text style={{ color: "#fff", fontWeight: "700" }}>
                    {dealToEdit.type === "detailed"
                      ? "FREE"
                      : `${dealToEdit.value} €`}
                  </Text>
                </View>
              </View>
              {/* item input fields */}
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                    marginTop: 25,
                  },
                ]}
                value={dealToEdit.title}
                placeholder="Titel:"
                onChangeText={(text) =>
                  setDealToEdit({ ...dealToEdit, title: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={dealToEdit.description}
                placeholder="Beschreibung:"
                onChangeText={(text) =>
                  setDealToEdit({ ...dealToEdit, description: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={dealToEdit.value}
                placeholder="Wert:"
                onChangeText={(text) =>
                  setDealToEdit({ ...dealToEdit, value: text })
                }
                keyboardType="numbers-and-punctuation"
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={dealToEdit.level}
                placeholder="Level"
                onChangeText={(text) =>
                  setDealToEdit({ ...dealToEdit, level: text })
                }
                autoCorrect={false}
              />
              <View
                style={{
                  width: "90%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setDealToEdit({ ...dealToEdit, type: "simple" });
                  }}
                  style={{
                    width: "48%",
                    height: 50,
                    backgroundColor:
                      dealToEdit.type === "simple" ? colors.green : "#3a3a3a",
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{ color: "#fff", fontSize: 17, fontWeight: "600" }}
                  >
                    Geldwert
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setDealToEdit({ ...dealToEdit, type: "detailed" });
                  }}
                  style={{
                    width: "48%",
                    height: 50,
                    backgroundColor:
                      dealToEdit.type === "detailed" ? colors.green : "#3a3a3a",
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{ color: "#fff", fontSize: 17, fontWeight: "600" }}
                  >
                    Kostenlos
                  </Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                onPress={() => {
                  updateDeal();
                }}
                style={{
                  width: "50%",
                  height: 60,
                  backgroundColor: "#f00",
                  marginTop: 35,
                  borderRadius: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 26, fontWeight: "500" }}
                >
                  Speichern
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
      )}
      {viewState === 4.6 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Deals bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(4);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <View
              style={{
                width: "90%",
                padding: 15,
                backgroundColor: `${sellerToEdit.color}bb`,
                marginBottom: 15,
                borderRadius: 100,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "80%" }}>
                <Text
                  style={{
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: 16,
                  }}
                >
                  {dealToAdd.title}
                </Text>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 10,
                  }}
                >
                  {dealToAdd.description}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "600",
                  }}
                >
                  LVL {dealToAdd.level}
                </Text>
                <Text style={{ color: "#fff", fontWeight: "700" }}>
                  {dealToAdd.type === "detailed"
                    ? "FREE"
                    : `${dealToAdd.value} €`}
                </Text>
              </View>
            </View>
            {/* item input fields */}
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                  marginTop: 25,
                },
              ]}
              value={dealToAdd.title}
              placeholder="Titel:"
              onChangeText={(text) =>
                setDealToAdd({ ...dealToAdd, title: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={dealToAdd.description}
              placeholder="Beschreibung:"
              onChangeText={(text) =>
                setDealToAdd({ ...dealToAdd, description: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={dealToAdd.value}
              placeholder="Wert:"
              onChangeText={(text) =>
                setDealToAdd({ ...dealToAdd, value: text })
              }
              keyboardType="numbers-and-punctuation"
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={dealToAdd.level}
              placeholder="Level"
              onChangeText={(text) =>
                setDealToAdd({ ...dealToAdd, level: text })
              }
              autoCorrect={false}
            />
            <View
              style={{
                width: "90%",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setDealToAdd({ ...dealToAdd, type: "simple" });
                }}
                style={{
                  width: "48%",
                  height: 50,
                  backgroundColor:
                    dealToAdd.type === "simple" ? colors.green : "#3a3a3a",
                  borderRadius: 25,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 17, fontWeight: "600" }}
                >
                  Geldwert
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setDealToAdd({ ...dealToAdd, type: "detailed" });
                }}
                style={{
                  width: "48%",
                  height: 50,
                  backgroundColor:
                    dealToAdd.type === "detailed" ? colors.green : "#3a3a3a",
                  borderRadius: 25,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 17, fontWeight: "600" }}
                >
                  Kostenlos
                </Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                addDeal();
              }}
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "#f00",
                marginTop: 35,
                borderRadius: 40,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "#fff", fontSize: 26, fontWeight: "500" }}>
                Speichern
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
      {/* Edit Cards */}
      {viewState === 5 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Karten bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(1);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          {stampcards && (
            <View style={{ alignItems: "center", marginTop: 25 }}>
              <Text style={{ marginBottom: 15, fontSize: 18 }}>
                Stampelkarte zum Seller wählen
              </Text>
              <Picker
                selectedValue={stampcardToEdit}
                onValueChange={(itemValue) => {
                  setStampcardToEdit(itemValue);
                }}
                style={{
                  marginBottom: 16,
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 8,
                  width: "90%",
                }}
              >
                <Picker.Item label="Stampcard wählen" value="" />
                {stampcards &&
                  stampcards.map((stampcard) => (
                    <Picker.Item
                      key={stampcard.id}
                      label={stampcard.name}
                      value={stampcard.id}
                    />
                  ))}
              </Picker>
              <TouchableOpacity
                onPress={() => {
                  updateSellerStampcard();
                }}
                style={{
                  width: Screen.width / 1.5,
                  height: 60,
                  borderRadius: 30,
                  backgroundColor: colors.red,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ fontSize: 20, fontWeight: "500", color: "#fff" }}
                >
                  Speichern
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
      )}
      {/* item bearbeiten */}
      {viewState === 6 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Item bearbeiten
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(3);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              marginTop: 15,
            }}
          >
            {/* item preview */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
                padding: 10,
                backgroundColor: "#ddd",
                borderRadius: 15,
              }}
            >
              <View style={{ width: "60%" }}>
                <Text
                  style={{
                    marginBottom: 3,
                    fontSize: 17,
                    color: "#3a3a3a",
                    fontWeight: "500",
                  }}
                >
                  {itemToEdit.name}
                </Text>
                <Text
                  style={{
                    marginBottom: 3,
                    fontSize: 10,
                    color: "#3a3a3a",
                  }}
                >
                  {itemToEdit.desc}
                </Text>
                {itemToEdit.duration && <Text>{itemToEdit.duration}</Text>}
                {itemToEdit.price && (
                  <Text
                    style={{
                      fontSize: 20,
                      color: "#3a3a3a",
                      fontWeight: "600",
                    }}
                  >
                    {itemToEdit.price} €
                  </Text>
                )}
              </View>
              <Image
                source={{ uri: itemToEdit.image }}
                resizeMode="cover"
                style={{
                  width: "40%",
                  height: "100%",
                  borderRadius: 20,
                  backgroundColor: "#ccc",
                }}
              />
            </View>
            {/* item input fields */}
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                  marginTop: 25,
                },
              ]}
              value={itemToEdit.name}
              placeholder="Item Name:"
              onChangeText={(text) =>
                setItemToEdit({ ...itemToEdit, name: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToEdit.desc}
              placeholder="Item Beschreibung:"
              onChangeText={(text) =>
                setItemToEdit({ ...itemToEdit, desc: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToEdit.price}
              placeholder="Item Preis:"
              onChangeText={(text) =>
                setItemToEdit({ ...itemToEdit, price: text })
              }
              keyboardType="numbers-and-punctuation"
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToEdit.image}
              placeholder="Bild Quelle eingeben"
              onChangeText={(text) =>
                setItemToEdit({ ...itemToEdit, image: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToEdit.duration}
              placeholder="Item Dauer bsp: 30 min / 1 std"
              onChangeText={(text) =>
                setItemToEdit({ ...itemToEdit, duration: text })
              }
              autoCorrect={false}
            />
            <TouchableOpacity
              onPress={editItem}
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "#f00",
                marginTop: 35,
                borderRadius: 40,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "#fff", fontSize: 26, fontWeight: "500" }}>
                Speichern
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
      {/* item Hinzufügen */}
      {viewState === 7 && (
        <ScrollView>
          {/* Header */}
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                marginVertical: 15,
                color: "#3a3a3a",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Item hinzufügen
            </Text>
            <TouchableOpacity
              style={{
                height: 50,
                paddingHorizontal: 30,
                position: "absolute",
                left: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccc",
                borderRadius: 25,
              }}
              onPress={() => {
                setViewState(3);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "500" }}>Back</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: Screen.width,
              alignItems: "center",
              marginTop: 15,
            }}
          >
            {/* item preview */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
                padding: 10,
                backgroundColor: "#ddd",
                borderRadius: 15,
              }}
            >
              <View style={{ width: "60%" }}>
                <Text
                  style={{
                    marginBottom: 3,
                    fontSize: 17,
                    color: "#3a3a3a",
                    fontWeight: "500",
                  }}
                >
                  {itemToAdd.name}
                </Text>
                <Text
                  style={{
                    marginBottom: 3,
                    fontSize: 10,
                    color: "#3a3a3a",
                  }}
                >
                  {itemToAdd.desc}
                </Text>
                {itemToAdd.duration && <Text>{itemToAdd.duration}</Text>}
                {itemToAdd.price && (
                  <Text
                    style={{
                      fontSize: 20,
                      color: "#3a3a3a",
                      fontWeight: "600",
                    }}
                  >
                    {itemToAdd.price} €
                  </Text>
                )}
              </View>
              <Image
                source={{ uri: itemToAdd.image }}
                resizeMode="cover"
                style={{
                  width: "40%",
                  height: "100%",
                  borderRadius: 20,
                  backgroundColor: "#ccc",
                }}
              />
            </View>
            {/* item input fields */}
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                  marginTop: 25,
                },
              ]}
              value={itemToAdd.name}
              placeholder="Item Name:"
              onChangeText={(text) =>
                setItemToAdd({ ...itemToAdd, name: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToAdd.desc}
              placeholder="Item Beschreibung:"
              onChangeText={(text) =>
                setItemToAdd({ ...itemToAdd, desc: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToAdd.price}
              placeholder="Item Preis:"
              onChangeText={(text) =>
                setItemToAdd({ ...itemToAdd, price: text })
              }
              keyboardType="numbers-and-punctuation"
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToAdd.image}
              placeholder="Bild Quelle eingeben"
              onChangeText={(text) =>
                setItemToAdd({ ...itemToAdd, image: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[
                styles.inputContainer,
                {
                  borderColor: "#3a3a3a",
                },
              ]}
              value={itemToAdd.duration}
              placeholder="Item Dauer bsp: 30 min / 1 std"
              onChangeText={(text) =>
                setItemToAdd({ ...itemToAdd, duration: text })
              }
              autoCorrect={false}
            />
            <TouchableOpacity
              onPress={addItem}
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "#f00",
                marginTop: 35,
                borderRadius: 40,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "#fff", fontSize: 26, fontWeight: "500" }}>
                Speichern
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default EditSeller;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    width: "100%",
    marginTop: 35,
  },

  inputContainer: {
    width: "90%",
    padding: 13,
    marginVertical: 6,
    borderWidth: 2,
    borderColor: "#bbbbbb",
    borderRadius: 10,
  },
});
