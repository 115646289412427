import { useState, useEffect } from "react";
import { LogBox } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
// Screens
import LoginScreen from "./screens/LoginScreen";
import ViewController from "./ViewController";
// Firebase
import { auth, db } from "./firebase";
import { getDoc, doc } from "firebase/firestore";

const Stack = createStackNavigator();

export default function App() {
  LogBox.ignoreLogs([
    "Warning: Async Storage has been extracted from react-native core",
  ]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const screenOptions = {
    headerShown: false,
    headerStyle: { backgroundColor: "black" },
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(db, "user", user.uid);
        try {
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            var isAdmin = userSnap.data().isAdmin;
            var isSalesman = userSnap.data().isSalesman;
            if (isAdmin || isSalesman) {
              setIsAuthenticated(true);
            }
          }
        } catch (error) {
          console.error("Error checking user:", error.message);
        }
      } else {
        setIsAuthenticated(false);
      }
    });
    return unsubscribe;
  }, []);

  const AppStack = () => {
    return (
      <NavigationContainer>
        <Stack.Navigator screenOptions={screenOptions}>
          {isAuthenticated ? (
            <Stack.Screen name="ViewController" component={ViewController} />
          ) : (
            <Stack.Screen name="Login" component={LoginScreen} />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    );
  };

  return <AppStack />;
}
