//Main react Stuff:
import React from "react";
import { Image, StyleSheet, LogBox, View, Text } from "react-native";
//Navigation:
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
//Views:
import AddSeller from "./screens/AddSeller";
import EditSeller from "./screens/EditSeller";
import AssignStampcard from "./screens/AssignStampcard";

// Variablen
const Tab = createBottomTabNavigator();

const ViewController = () => {
  LogBox.ignoreLogs([
    "Warning: Async Storage has been extracted from react-native core",
  ]);

  return (
    <Tab.Navigator
      initialRouteName="AddSeller"
      screenOptions={{
        tabBarShowLabel: false,
        tabBarStyle: {
          height: 75,
          style: styles.tabBar,
        },
      }}
    >
      <Tab.Screen
        name="Stampcards"
        component={AssignStampcard}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabIconView}>
              <Image
                source={require("./assets/icons/cards.png")}
                resizeMode="contain"
                style={{
                  width: 20,
                  height: 20,
                  tintColor: focused ? "#DF2839" : "#4A4A4A",
                  marginTop: 5,
                }}
              />
              <Text
                style={{
                  color: focused ? "#DF2839" : "#4A4A4A",
                  fontSize: 10,
                  paddingTop: 5,
                }}
              >
                Stempelkarten
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="AddSeller"
        component={AddSeller}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabIconView}>
              <Image
                source={require("./assets/icons/home.png")}
                resizeMode="contain"
                style={{
                  width: 20,
                  height: 20,
                  tintColor: focused ? "#DF2839" : "#4A4A4A",
                  marginTop: 5,
                }}
              />
              <Text
                style={{
                  color: focused ? "#DF2839" : "#4A4A4A",
                  fontSize: 10,
                  paddingTop: 5,
                }}
              >
                Seller hinzufügen
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="EditSeller"
        component={EditSeller}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabIconView}>
              <Image
                source={require("./assets/icons/pen.png")}
                resizeMode="contain"
                style={{
                  width: 20,
                  height: 20,
                  tintColor: focused ? "#DF2839" : "#4A4A4A",
                  marginTop: 5,
                }}
              />
              <Text
                style={{
                  color: focused ? "#DF2839" : "#4A4A4A",
                  fontSize: 10,
                  paddingTop: 5,
                }}
              >
                Edit Seller
              </Text>
            </View>
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default ViewController;

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: "black",
    height: 60,
  },

  tabIconView: {
    alignItems: "center",
    justifyContent: "center",
    width: 110,
  },
});
