import Screen from "./Screen";

const constants = {
  stampcard: "stampcard",
  seller: "seller",
  user: "user",
  analytics: "analytics",
  stampcards: "stampcards",
  deals: "deals",
  templateSellerID: "N3SNMfaVqkoNoTuOvLCp",
};

const STAMPCARD_HEIGHT = Screen.width / 1.7;
const STAMPCARD_WIDTH = Screen.width / 1.1;
const STAMPCARD_BORDER_RADIUS = 15;

const filters = [
  "Burger 🍔",
  "Pizza 🍕",
  "Japanisch 🍣",
  "Thai 🍛",
  "Döner 🥙",
  "Maniküre 💅",
  "Hairstyling 🪒",
  "Snacks 🍫",
  "Getränke 🧃",
  "Bier 🍻",
  "Café ☕",
];

export {
  constants,
  filters,
  STAMPCARD_BORDER_RADIUS,
  STAMPCARD_HEIGHT,
  STAMPCARD_WIDTH,
};
