import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  Button,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { launchImageLibraryAsync, MediaTypeOptions } from "expo-image-picker";
/* import ColorPicker from "../helpers/ColorPicker"; */
import ColorPicker from "react-native-wheel-color-picker";
import { Picker } from "@react-native-picker/picker";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { constants } from "../helpers/constants";
import Stampcard from "../components/Stampcard/Stampcard";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const AddStampCard = ({ goBack, sellers }) => {
  const [seller, setSeller] = useState({
    name: "",
    address: "",
    favIcon: "",
    image: "",
    sellerID: "",
    color: "#555555",
  });
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (isSellerValid()) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [seller]);

  const isSellerValid = () => {
    const { name, address, favIcon, image, sellerID, color } = seller;

    // Apply your validation rules for each property
    const isNameValid = name.trim().length > 0;
    const isAddressValid = address.trim().length > 0;
    const isFavIconValid = favIcon.trim().length > 0;
    const isImageValid = image.trim().length > 0;
    const isSellerIDValid = sellerID.trim().length > 0;
    const isColorValid = color.trim().length > 0;

    // Return true if all validations pass, false otherwise
    return (
      isNameValid &&
      isAddressValid &&
      isFavIconValid &&
      isImageValid &&
      isSellerIDValid &&
      isColorValid
    );
  };

  // --> Image picker and uploader
  const handleUpload = async (uploadType) => {
    let result = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.5,
    });

    if (!result.canceled) {
      const fireStorage = getStorage();
      if (uploadType === "image") {
        //const ref = ref(storage, 'image.jpg'); //how the image will be addressed inside the storage
        const storageRef = ref(
          fireStorage,
          `/${seller.name}/${Math.floor(Math.random() * 10000) + 1}.jpg`
        );

        //convert image to array of bytes
        const img = await fetch(result.assets[0].uri);
        const bytes = await img.blob();

        uploadBytesResumable(storageRef, bytes).then(() => {
          getDownloadURL(storageRef).then((link) => {
            handleInputChange("image", link);
          }),
            console.log("storage url created and saved");
        });
      } else if (uploadType === "favicon") {
        const storageRef = ref(fireStorage, `/${seller.name}/favicon.jpg`);

        // Convert image to array of bytes
        const img = await fetch(result.assets[0].uri);
        const bytes = await img.blob();

        uploadBytesResumable(storageRef, bytes).then(() => {
          getDownloadURL(storageRef).then((link) => {
            handleInputChange("favIcon", link);
          }),
            console.log("habs hingeschrieben!");
        });
      }
    }
  };

  /*   const handleUpload = async (uploadType) => {
    let result = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      const url = result.assets[0].uri;
      if (uploadType === "image") {
        handleInputChange("image", url);
      } else if (uploadType === "favicon") {
        handleInputChange("favIcon", url);
      }
    }
  }; */

  const handleInputChange = (key, value) => {
    setSeller((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSellerCreation = async () => {
    if (isSellerValid()) {
      console.log("creating...");
      const stampcardDocRef = collection(db, constants.stampcard);
      try {
        await addDoc(stampcardDocRef, {
          name: seller.name,
          adress: seller.address,
          color: seller.color,
          favicon: seller.favIcon,
          image: seller.image,
          sellerID: seller.sellerID,
        });
        console.log("Seller created successfully");
        setSeller({
          name: "",
          address: "",
          favIcon: "",
          image: "",
          sellerID: "",
          color: "#fff",
        });
      } catch (error) {
        console.error("Error creating seller:", error);
      }
    } else {
      console.log("Seller data is not valid");
    }
  };

  const colorPickerRef = useRef();

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: 50,
            marginTop: 10,
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: "600" }}>Hinzufügen</Text>
          <TouchableOpacity
            style={{
              paddingHorizontal: 15,
              paddingVertical: 7,
              backgroundColor: "#333",
              borderRadius: 30,
            }}
            onPress={goBack}
          >
            <Text style={{ fontSize: 13, fontWeight: "500", color: "#fff" }}>
              Zurück
            </Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.headerTitle}>Name:</Text>

        <TextInput
          value={seller.name}
          onChangeText={(text) => handleInputChange("name", text)}
          placeholder="Enter name"
          style={styles.input}
        />
        <Text style={styles.headerTitle}>Address:</Text>
        <TextInput
          value={seller.address}
          onChangeText={(text) => handleInputChange("address", text)}
          placeholder="Enter address"
          style={styles.input}
        />
        <Text style={styles.headerTitle}>Upload a favicon:</Text>
        <TouchableOpacity onPress={() => handleUpload("favicon")}>
          {seller.favIcon ? (
            <Image
              source={{ uri: seller.favIcon }}
              style={styles.imagePreview}
            />
          ) : (
            <View style={styles.uploadButton}>
              <Text style={styles.uploadText}>Upload</Text>
            </View>
          )}
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Upload an image:</Text>
        <TouchableOpacity onPress={() => handleUpload("image")}>
          {seller.image ? (
            <Image
              source={{ uri: seller.image }}
              style={styles.imagePreviewLarge}
            />
          ) : (
            <View style={styles.uploadButtonLarge}>
              <Text style={styles.uploadText}>Upload</Text>
            </View>
          )}
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Seller ID:</Text>
        <Picker
          selectedValue={seller.sellerID}
          onValueChange={(itemValue, itemIndex) =>
            handleInputChange("sellerID", itemValue)
          }
          style={styles.input}
        >
          <Picker.Item label="Select a seller ID" value="" />
          {sellers &&
            sellers.map((seller) => (
              <Picker.Item
                key={seller.id}
                label={seller.name}
                value={seller.id}
              />
            ))}
        </Picker>
        <Text style={styles.headerTitle}>Color:</Text>
        <ColorPicker
          ref={colorPickerRef}
          color={seller.color !== "" ? seller.color : null}
          onColorChangeComplete={(color) => handleInputChange("color", color)}
          thumbSize={40}
          sliderSize={20}
          row={false}
        />
        <Text style={styles.headerTitle}>Selected color: {seller.color}</Text>

        <View>
          <Text style={styles.headerTitle}>Stampcard Preview</Text>
          <Stampcard
            name={seller.name}
            address={seller.address}
            color={seller.color}
            favIcon={seller.favIcon}
            image={seller.image}
          />
        </View>

        <TouchableOpacity
          style={[
            styles.buttonContainer,
            { backgroundColor: validated ? "#4681f4" : "#808080" },
          ]}
          disabled={!validated}
          onPress={handleSellerCreation}
        >
          <Text style={styles.button}>Create</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingHorizontal: 15,
    backgroundColor: "b",
  },
  label: {
    marginBottom: 8,
    fontWeight: "bold",
  },
  input: {
    marginBottom: 16,
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
  uploadButton: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  uploadButtonLarge: {
    width: "100%",
    height: 200,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  imagePreview: {
    width: 100,
    height: 100,
    borderRadius: 4,
    resizeMode: "cover",
    marginBottom: 16,
  },
  imagePreviewLarge: {
    width: "100%",
    height: 200,
    borderRadius: 4,
    resizeMode: "cover",
    marginBottom: 16,
  },
  colorPicker: {
    marginBottom: 16,
    height: 200,
  },
  uploadText: { color: "blue", fontWeight: "bold" },
  buttonContainer: {
    marginVertical: 15,
    height: 50,
    margin: 10,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    color: "white",
    fontWeight: "900",
  },
  headerTitle: {
    marginVertical: 15,
    fontSize: 14,
    fontWeight: "900",
  },
});

export default AddStampCard;
