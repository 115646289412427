import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  ScrollView,
  Platform,
  StyleSheet,
} from "react-native";
// Firebase
import { auth } from "../firebase";
import { db } from "../firebase";
import { collection, addDoc, GeoPoint } from "firebase/firestore"; //access to firestore database
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"; //access to storage database
// Helpers
import Screen from "../helpers/Screen";
import {
  launchImageLibraryAsync,
  MediaTypeOptions,
  requestMediaLibraryPermissionsAsync,
} from "expo-image-picker";

const AddSeller = () => {
  // Constants
  const width = Screen.width;

  // View States
  const [viewState, setViewState] = useState(0);

  // State Variables
  const [opentimes, setOpentimes] = useState({
    name: "",
    dayntime: [],
  });
  const [dayntimes, setDayntimes] = useState([]);
  const [dayntime, setDayntime] = useState({
    day: "",
    time: "",
  });
  const [keywords, setKeywords] = useState([""]);
  const [variationDuration, setVariationDuration] = useState("");
  const [variationPrice, setVariationPrice] = useState("");
  const [variationImage, setVariationImage] = useState("");
  const [variationDesc, setVariationDesc] = useState("");
  const [variationName, setVariationName] = useState("");
  const [variations, setVariations] = useState([]);
  const [itemArrLoc, setItemArrLoc] = useState();
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemDuration, setItemDuration] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemImage, setItemImage] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [itemName, setItemName] = useState("");
  const [items, setItems] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [dayntimeValidated, setDayntimeValidated] = useState(false);
  const [itemValidated, setItemValidated] = useState(false);
  const [variationValidated, setVariationValidated] = useState(false);
  const [categoryValidated, setCategoryValidated] = useState(false);

  //Seller Model
  const [seller, setSeller] = useState({
    name: "",
    adress: "",
    description: "",
    coordinates: new GeoPoint(0, 0),
    favicon: "",
    phone: "",
    color: "#aaaaaa",
    instagram: "",
  });

  // Functions
  // --> Finishers
  const finishSeller = () => {
    opentimes.dayntime = dayntimes;
    if (
      categories !== [] &&
      opentimes.dayntime !== [] &&
      imageUrls !== [] &&
      seller.favicon !== ""
    ) {
      pushSeller();
    } else {
      console.log("categories oder opentimes nicht vollständig");
    }
  };

  const pushSeller = async () => {
    const obj = {
      name: seller.name,
      adress: seller.adress,
      description: seller.description,
      coordinates: seller.coordinates,
      favicon: seller.favicon,
      phone: seller.phone,
      images: imageUrls,
      isOpen: true,
      color: seller.color,
      instagram: seller.instagram,
      categories: categories,
      opentimes: opentimes,
      keywords: keywords,
    };
    console.log(obj);
    await addDoc(collection(db, "seller"), obj);
    console.log("Success");
    //navigation.goBack()
  };

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  // --> Image picker and uploader
  const handleImageUpload = async (uploadType) => {
    let result = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });

    if (!result.canceled) {
      const fireStorage = getStorage(); //the storage itself
      if (uploadType === "image") {
        //const ref = ref(storage, 'image.jpg'); //how the image will be addressed inside the storage
        const refugee = ref(
          fireStorage,
          `/${seller.name}/${Math.floor(Math.random() * 10000) + 1}.jpg`
        );

        //convert image to array of bytes
        const img = await fetch(result.assets[0].uri);
        const bytes = await img.blob();

        //await uploadBytes(refugee, bytes); //upload images
        uploadBytesResumable(refugee, bytes).then(() => {
          getDownloadURL(refugee).then((link) => {
            //setTheArray([...theArray, newElement]);
            setImageUrls([...imageUrls, link]);
          }),
            console.log("habs hingeschrieben!");
        });
      } else if (uploadType === "favicon") {
        //const ref = ref(storage, 'image.jpg'); //how the image will be addressed inside the storage
        const refugee = ref(fireStorage, `/${seller.name}/favicon.jpg`);

        //convert image to array of bytes
        const img = await fetch(result.assets[0].uri);
        const bytes = await img.blob();

        //await uploadBytes(refugee, bytes); //upload images
        uploadBytesResumable(refugee, bytes).then(() => {
          getDownloadURL(refugee).then((link) => {
            setSeller({ ...seller, favicon: link });
          }),
            console.log("habs hingeschrieben!");
        });
      }
    }
  };

  // --> Adders
  const addDayntime = () => {
    setDayntimes((oldArray) => [...oldArray, dayntime]);
    resetOpentimes();
  };

  const addCategory = () => {
    if (items.length !== 0) {
      const newCategory = {
        name: categoryName,
        items: items,
      };
      setCategories((oldArray) => [...oldArray, newCategory]);
      resetCategories();
      resetItemsArray();
    }
  };

  const addItem = () => {
    if (itemName && itemDesc && itemPrice !== "") {
      if (!isNaN(itemPrice)) {
        setItemValidated(true);
        const newItem = {
          name: itemName,
          desc: itemDesc,
          price: Number(itemPrice),
        };
        if (itemImage !== "") {
          newItem.image = itemImage;
        }
        if (itemDuration !== "") {
          newItem.duration = itemDuration;
        }
        if (variations.length !== 0) {
          newItem.variations = variations;
        }
        resetItemFields();
        resetVariationsArray();
        setItems((oldArray) => [...oldArray, newItem]);
      } else {
        console.log("price is not a number ");
      }
    }
  };

  const addVariation = () => {
    if (variationName && variationDesc && variationPrice !== "") {
      if (!isNaN(variationPrice)) {
        setVariationValidated(true);
        const newVariation = {
          name: variationName,
          desc: variationDesc,
          price: Number(variationPrice),
        };
        if (variationImage !== "") {
          newVariation.image = variationImage;
        }
        if (variationDuration !== "") {
          newVariation.duration = variationDuration;
        }
        resetVariationFields();
        setVariations((oldArray) => [...oldArray, newVariation]);
      } else {
        console.log("price is not a number ");
      }
    }
  };

  // gets string and splits in two
  const splitCoordinates = (geopointString) => {
    const coordinatesArr = geopointString.split(", ");
    const breite = parseFloat(coordinatesArr[0]);
    const länge = parseFloat(coordinatesArr[1]);

    const geo = new GeoPoint(breite, länge);

    return geo;
  };

  // EDITORS

  // --> Edit
  const editItem = () => {
    const updatedItem = {
      name: itemToEdit.name,
      desc: itemToEdit.desc,
      price: Number(itemToEdit.price),
    };
    if (itemToEdit.image !== "") {
      updatedItem.image = itemToEdit.image;
    }
    if (itemToEdit.duration !== "") {
      updatedItem.duration = itemDuration;
    }
    if (itemToEdit.variations) {
      updatedItem.variations = itemToEdit.variations;
    }
    const categoriesArr = [...categories];
    const newItemsArray = [...categories[itemArrLoc.catIndex].items];
    newItemsArray[itemArrLoc.itemIndex] = updatedItem;
    categoriesArr[itemArrLoc.catIndex].items = newItemsArray;
    setCategories(categoriesArr);
    setViewState(2);
  };

  // --> Delete
  const deleteCategory = (index) => {
    const newArray = [...categories];
    newArray.splice(index, 1);
    setCategories(newArray);
  };

  const deleteCategoryItem = (catIndex, index) => {
    const categoriesArr = [...categories];
    const newItemsArray = [...categories[catIndex].items];
    newItemsArray.splice(index, 1);
    categoriesArr[catIndex].items = newItemsArray;
    setCategories(categoriesArr);
  };

  const deleteVariation = (index) => {
    const newArray = [...variations];
    newArray.splice(index, 1);
    setVariations(newArray);
  };

  // --> Resetters
  const resetOpentimes = () => {
    setDayntime({
      day: "",
      time: "",
    });
  };
  const resetItemFields = () => {
    setItemName("");
    setItemDesc("");
    setItemPrice("");
    setItemImage("");
    setVariations("");
  };

  const resetVariationFields = () => {
    setVariationName("");
    setVariationDesc("");
    setVariationPrice("");
    setVariationDuration("");
    setVariationImage("");
  };

  const resetItemsArray = () => {
    setItems([]);
  };

  const resetVariationsArray = () => {
    setVariations([]);
  };

  const resetCategories = () => {
    setCategoryName("");
  };

  // UseEffects
  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } = await requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (itemName && itemDesc && itemPrice != "") {
      if (!isNaN(itemPrice)) {
        setItemValidated(true);
      } else {
        console.log("not valid");
      }
    } else {
      setItemValidated(false);
    }
  }, [itemPrice, itemName, itemDesc]);

  // Category Validation
  useEffect(() => {
    if (categoryName && items.length > 0) {
      setCategoryValidated(true);
    } else {
      setCategoryValidated(false);
    }
  }, [categoryName, items]);

  // Dayntime Validation
  useEffect(() => {
    if (dayntime.day && dayntime.time !== "") {
      setDayntimeValidated(true);
    } else {
      setDayntimeValidated(false);
    }
    0;
  }, [dayntime.day, dayntime.time]);

  return (
    <View style={{ width: width, flex: 1 }}>
      {/* First Step */}
      {viewState === 0 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            {/* Image Containers */}
            <View>
              <Image
                source={{ uri: imageUrls[0] }}
                resizeMode="cover"
                style={{
                  width: width,
                  height: width / 2,
                  backgroundColor: "#ddd",
                }}
              />
              <View
                style={{
                  width: width / 4,
                  height: width / 4,
                  backgroundColor: "#ccc",
                  position: "absolute",
                  bottom: -(width / 4) / 2,
                  left: 15,
                  borderRadius: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  source={{
                    uri: seller.favicon != "" ? seller.favicon : undefined,
                  }}
                  resizeMode="cover"
                  style={{
                    width: width / 5.5,
                    height: width / 5.5,
                    borderRadius: 15,
                  }}
                />
              </View>
            </View>
            <View
              style={{
                width: Screen.width,
                alignItems: "center",
                marginTop: width / 4 / 2 + 15,
              }}
            >
              <TextInput
                style={styles.inputContainer}
                value={seller.name}
                placeholder="Name:"
                onChangeText={(text) => setSeller({ ...seller, name: text })}
                autoCorrect={false}
              />
              <TextInput
                style={styles.inputContainer}
                value={seller.adress}
                placeholder="Adresse: Beispielstraße 12, 12345 Stampithausen"
                onChangeText={(text) => setSeller({ ...seller, adress: text })}
                autoCorrect={false}
              />
              {/* Image Pickers */}
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: width / 1.2,
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: seller.color != "" ? seller.color : "#000",
                    padding: 10,
                    marginVertical: 5,
                    borderRadius: 10,
                  }}
                  onPress={() => handleImageUpload("image")}
                >
                  <Text
                    style={{ color: "white", fontWeight: "800", fontSize: 14 }}
                  >
                    Bilder auswählen: {imageUrls.length}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: seller.color != "" ? seller.color : "#000",
                    padding: 10,
                    marginVertical: 5,
                    borderRadius: 10,
                  }}
                  onPress={() => handleImageUpload("favicon")}
                >
                  <Text
                    style={{ color: "white", fontWeight: "800", fontSize: 14 }}
                  >
                    Logo auswählen
                  </Text>
                </TouchableOpacity>
              </View>
              <TextInput
                style={styles.inputContainer}
                value={seller.description}
                placeholder="Beschreibung: 'Dies ist der Laden meiner Träume...'"
                onChangeText={(text) =>
                  setSeller({ ...seller, description: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={styles.inputContainer}
                value={seller.phone}
                keyboardType="number-pad"
                placeholder="Telefon Nummer: "
                onChangeText={(text) => setSeller({ ...seller, phone: text })}
              />
              <TextInput
                style={styles.inputContainer}
                value={seller.instagram}
                placeholder="Instagram/Facebook Link:"
                onChangeText={(text) =>
                  setSeller({ ...seller, instagram: text })
                }
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="url"
              />
              <TextInput
                style={styles.inputContainer}
                value={seller.color}
                placeholder="Seller Farbe: z.B. #ABABAB"
                onChangeText={(text) => setSeller({ ...seller, color: text })}
                autoCorrect={false}
              />
              <TextInput
                style={styles.inputContainer}
                placeholder="---> Hier erst Koordinaten anpassen und Kopieren <---"
                autoCorrect={false}
                autoCapitalize="none"
              />
              <TextInput
                style={styles.inputContainer}
                value={seller.latitude}
                placeholder="Koordinaten bspl.: 0.123, 0.234"
                onChangeText={(text) =>
                  setSeller({ ...seller, coordinates: splitCoordinates(text) })
                }
              />
            </View>
            <TouchableOpacity
              onPress={() => setViewState(1)}
              style={{
                width: width / 1.6,
                height: 50,
                backgroundColor: seller.color != "" ? seller.color : "#000",
                marginTop: 25,
                marginBottom: 100,
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "#fff", fontWeight: "600", fontSize: 20 }}>
                Weiter
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
      {/* Opentimes */}
      {viewState === 1 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                width: width,
                marginTop: 45,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => setViewState(0)}
                style={{
                  width: width / 3,
                  height: 35,
                  backgroundColor: "#3a3a3a90",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                >
                  back
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 20, fontSize: 17, fontWeight: "600" }}>
                Öffnungszeiten
              </Text>
            </View>
            <View style={{ marginBottom: 15, width: width / 1.1 }}>
              <Text
                style={{ marginBottom: 3, fontSize: 20, fontWeight: "500" }}
              >
                {opentimes.name}
              </Text>
              {dayntimes.map((daytime) => {
                return (
                  <View
                    key={daytime.day}
                    style={{ flexDirection: "row", marginTop: 8 }}
                  >
                    <Text
                      style={{
                        color: "#4A4A4A",
                        fontSize: 16,
                      }}
                    >
                      {daytime.day}
                    </Text>
                    <Text
                      style={{
                        color: "#3A3A3A",
                        fontSize: 16,
                        marginLeft: 8,
                      }}
                    >
                      {daytime.time}
                    </Text>
                  </View>
                );
              })}
            </View>
            <TextInput
              style={[styles.inputContainer, { borderColor: "#aaaaaa" }]}
              value={opentimes.name}
              placeholder="z.B.: 'Öffnungszeiten:', 'Partys', 'Veranstaltungen:'... "
              onChangeText={(text) =>
                setOpentimes({ ...opentimes, name: text })
              }
              autoCorrect={false}
            />
            <TextInput
              style={[styles.inputContainer, { borderColor: "#aaaaaa" }]}
              value={dayntime.day}
              placeholder="Tage: z.B.: 'Mo - Fr'"
              onChangeText={(text) => setDayntime({ ...dayntime, day: text })}
              autoCorrect={false}
            />
            <TextInput
              style={[styles.inputContainer, { borderColor: "#aaaaaa" }]}
              value={dayntime.time}
              placeholder="Zeit: z.B.: '10:00 - 19:00'"
              onChangeText={(text) => setDayntime({ ...dayntime, time: text })}
              autoCorrect={false}
            />
            <TouchableOpacity
              enabled={dayntimeValidated}
              style={[
                styles.buttonStyle,
                !dayntimeValidated
                  ? { backgroundColor: "gray" }
                  : { backgroundColor: "#aa00ff" },
              ]}
              onPress={addDayntime}
            >
              <Text style={styles.buttonText}>Öffnungszeit Hinzufügen</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setViewState(2)}
              style={{
                marginVertical: 75,
                width: width / 2,
                height: 50,
                backgroundColor: "#3a3a3a",
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "600", color: "#fff" }}>
                Weiter
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      )}
      {/* Categories and Items */}
      {viewState === 2 && (
        <View style={{ width: Screen.width, flex: 1, flexDirection: "row" }}>
          {/* Preview */}
          {Screen.width >= Screen.height && (
            <ScrollView
              style={{
                width: "40%",
                flex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Image
                source={imageUrls[0] !== "" ? { uri: imageUrls[0] } : null}
                resizeMode="cover"
                style={{
                  width: "100%",
                  height: Screen.width / 2 / 2,
                  backgroundColor: "#ddd",
                }}
              />
              {/* Title and buttons */}
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginTop: 15,
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      color: "#3a3a3a",
                      fontSize: 24,
                      fontWeight: "500",
                    }}
                  >
                    {seller.name}
                  </Text>
                  <Text
                    style={{ marginTop: 3, fontSize: 20, color: "#008f81" }}
                  >
                    Jetzt geöffnet
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      width: Screen.width / 3 / 12,
                      height: Screen.width / 3 / 12,
                      borderRadius: Screen.width / 3 / 12,
                      backgroundColor: "#DF2839",
                      marginRight: 10,
                    }}
                  />
                  <View
                    style={{
                      width: Screen.width / 3 / 12,
                      height: Screen.width / 3 / 12,
                      borderRadius: Screen.width / 3 / 12,
                      backgroundColor: "#DF2839",
                      marginRight: 10,
                    }}
                  />
                  <View
                    style={{
                      width: Screen.width / 3 / 12,
                      height: Screen.width / 3 / 12,
                      borderRadius: Screen.width / 3 / 12,
                      backgroundColor: "#DF2839",
                    }}
                  />
                </View>
              </View>
              {/* Opentimes */}
              <View style={{ marginLeft: 15, marginTop: 25 }}>
                <Text
                  style={{ color: "#3a3a3a", fontSize: 22, marginBottom: 15 }}
                >
                  {opentimes.name}
                </Text>
                {dayntimes.map((daytime, index) => {
                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginBottom: 10 }}
                    >
                      <Text
                        style={{
                          color: "#4a4a4a",
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      >
                        {daytime.day}
                      </Text>
                      <Text
                        style={{
                          color: "#4a4a4a",
                          fontSize: 20,
                          fontWeight: "500",
                        }}
                      >
                        {daytime.time} Uhr
                      </Text>
                    </View>
                  );
                })}
              </View>
              {/* Categories */}
              <View
                style={{
                  marginTop: 30,
                  paddingBottom: 15,
                  paddingLeft: 15,
                  backgroundColor: "#008f8115",
                }}
              >
                <Text
                  style={{
                    color: "#008f81",
                    marginTop: 25,
                    fontSize: 30,
                    fontWeight: "600",
                  }}
                >
                  Kategorien
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  minHeight: Screen.width / 2 / 8,
                  backgroundColor: "#008f8115",
                  paddingHorizontal: 15,
                  paddingBottom: 20,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {categories.map((category, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        backgroundColor: "#fff",
                        paddingHorizontal: 15,
                        height: 32,
                        marginTop: 10,
                        marginRight: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: "#4a4a4a",
                          fontSize: 18,
                          fontWeight: "300",
                        }}
                      >
                        {category.name}
                      </Text>
                    </View>
                  );
                })}
              </View>
              {/* Items */}
              <View>
                {categories.map((category, index) => {
                  return (
                    <View
                      key={index}
                      style={{ paddingHorizontal: 25, marginTop: 35 }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 32,
                            fontWeight: "600",
                            color: "#008f81",
                          }}
                        >
                          {category.name}
                        </Text>
                        <TouchableOpacity
                          onPress={() => deleteCategory(index)}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            backgroundColor: "#f00",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: 15,
                          }}
                        >
                          <Text style={{ color: "#fff", fontSize: 20 }}>-</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginTop: 15 }}>
                        {category.items.map((item, itemIndex) => {
                          return (
                            <View
                              key={itemIndex}
                              style={{
                                marginTop: 25,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: "#3a3a3a20",
                                borderRadius: 15,
                                paddingVertical: 12,
                                paddingHorizontal: 15,
                              }}
                            >
                              {/* Delete and edit */}
                              <TouchableOpacity
                                onPress={() => {
                                  setItemToEdit(item);
                                  setItemArrLoc({
                                    catIndex: index,
                                    itemIndex: itemIndex,
                                  });
                                  setViewState(6);
                                }}
                                style={{
                                  width: 65,
                                  height: 30,
                                  borderRadius: 20,
                                  backgroundColor: "#3a3a3a",
                                  marginRight: 15,
                                  position: "absolute",
                                  top: -15,
                                  right: -30,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 20,
                                    color: "#fff",
                                    fontWeight: "600",
                                  }}
                                >
                                  Edit
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={() =>
                                  deleteCategoryItem(index, itemIndex)
                                }
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 20,
                                  backgroundColor: "#f00",
                                  position: "absolute",
                                  top: -15,
                                  left: -15,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 30,
                                    color: "#fff",
                                    fontWeight: "600",
                                  }}
                                >
                                  -
                                </Text>
                              </TouchableOpacity>
                              {/* View */}
                              <View>
                                <Text
                                  style={{
                                    fontSize: 26,
                                    fontWeight: "500",
                                    color: "#3a3a3a",
                                  }}
                                >
                                  {item.name}
                                </Text>
                                {item.duration && (
                                  <Text
                                    style={{
                                      color: "#008f81",
                                      fontSize: 18,
                                      marginTop: 5,
                                    }}
                                  >
                                    dauer: {item.duration}
                                  </Text>
                                )}
                                {item.desc && (
                                  <Text
                                    style={{
                                      color: "#4a4a4a",
                                      fontSize: 18,
                                      marginTop: 5,
                                    }}
                                  >
                                    {item.desc}
                                  </Text>
                                )}
                                <Text
                                  style={{
                                    color: "#11585c",
                                    fontSize: 28,
                                    fontWeight: "600",
                                    marginTop: 5,
                                  }}
                                >
                                  {item.variations ? "ab " : ""}
                                  {item.price.toFixed(2)} €
                                </Text>
                              </View>
                              {item.image && (
                                <Image
                                  source={{ uri: item.image }}
                                  resizeMode="cover"
                                  style={{
                                    width: Screen.width / 10,
                                    height: Screen.width / 10 / 1.6,
                                    borderRadius: 20,
                                    backgroundColor: "#000",
                                  }}
                                />
                              )}
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  );
                })}
                <View style={{ width: 20, height: 200 }} />
              </View>
            </ScrollView>
          )}
          <ScrollView
            style={{
              width: Screen.width >= Screen.height ? "60%" : "100%",
              flex: 1,
            }}
          >
            <View
              style={{
                width: "100%",
                flex: 1,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 45,
                  marginBottom: 15,
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => setViewState(1)}
                  style={{
                    width: width / 5,
                    height: 35,
                    backgroundColor: "#3a3a3a90",
                    alignSelf: "flex-start",
                    marginLeft: 15,
                    borderRadius: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                  >
                    back
                  </Text>
                </TouchableOpacity>
                <Text
                  style={{
                    marginLeft: 20,
                    fontSize: 17,
                    fontWeight: "600",
                  }}
                >
                  Menü Karte
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={[
                    styles.header,
                    {
                      backgroundColor: seller.color ? seller.color : "#3a3a3a",
                    },
                  ]}
                  onPress={() => setViewState(3)}
                >
                  <Text style={styles.headerTitle}>
                    Kategorien: {categories.length}
                  </Text>
                </TouchableOpacity>
                <TextInput
                  style={[styles.inputContainer, { borderColor: seller.color }]}
                  value={categoryName}
                  placeholder="Kategorie Name:"
                  onChangeText={(text) => setCategoryName(text)}
                  autoCorrect={false}
                />
                <TouchableOpacity
                  style={[
                    styles.header,
                    {
                      backgroundColor: "#3a3a3a",
                    },
                  ]}
                  onPress={() => setViewState(4)}
                >
                  <Text style={styles.headerTitle}>
                    {items.length} Items zur Kategorie: {categoryName}
                  </Text>
                </TouchableOpacity>
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={itemName}
                  placeholder="Item Name:"
                  onChangeText={(text) => setItemName(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={itemDesc}
                  placeholder="Item Beschreibung:"
                  onChangeText={(text) => setItemDesc(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={itemPrice}
                  placeholder="Item Preis:"
                  onChangeText={(text) => setItemPrice(text)}
                  keyboardType="numbers-and-punctuation"
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={itemImage}
                  placeholder="Bild Quelle eingeben"
                  onChangeText={(text) => setItemImage(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={itemDuration}
                  placeholder="Item Dauer bsp: 30 min / 1 std"
                  onChangeText={(text) => setItemDuration(text)}
                  autoCorrect={false}
                />
                <TouchableOpacity
                  onPress={() => setViewState(5)}
                  style={[
                    styles.header,
                    {
                      backgroundColor: "#3a3a3a",
                    },
                  ]}
                >
                  <Text style={styles.headerTitle}>
                    {variations.length} Variationen zum Item
                  </Text>
                </TouchableOpacity>
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={variationName}
                  placeholder="Variation Name:"
                  onChangeText={(text) => setVariationName(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={variationDesc}
                  placeholder="Variation Beschreibung:"
                  onChangeText={(text) => setVariationDesc(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={variationPrice}
                  placeholder="Variation Preis:"
                  onChangeText={(text) => setVariationPrice(text)}
                  keyboardType="numbers-and-punctuation"
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={variationImage}
                  placeholder="Bild Quelle eingeben"
                  onChangeText={(text) => setVariationImage(text)}
                  autoCorrect={false}
                />
                <TextInput
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "#3a3a3a",
                    },
                  ]}
                  value={variationDuration}
                  placeholder="Variation Dauer bsp: 30 min / 1 std"
                  onChangeText={(text) => setVariationDuration(text)}
                  autoCorrect={false}
                />
                <TouchableOpacity
                  enabled={variationValidated}
                  style={[
                    styles.buttonStyle,
                    !variationValidated
                      ? { backgroundColor: "gray" }
                      : { backgroundColor: "#0f0" },
                  ]}
                  onPress={addVariation}
                >
                  <Text style={styles.buttonText}>Variation hinzufügen</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  enabled={itemValidated}
                  style={[
                    styles.buttonStyle,
                    !itemValidated
                      ? { backgroundColor: "gray" }
                      : { backgroundColor: "#bbaaff" },
                  ]}
                  onPress={addItem}
                >
                  <Text style={styles.buttonText}>
                    Item mit {variations.length} Variationen Hinzufügen
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  enabled={categoryValidated}
                  style={[
                    styles.buttonStyle,
                    !categoryValidated
                      ? { backgroundColor: "gray" }
                      : { backgroundColor: "#ffaabb" },
                  ]}
                  onPress={addCategory}
                >
                  <Text style={styles.buttonText}>
                    Kategorie mit {items.length} Items Hinzufügen
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    width: "70%",
                    backgroundColor: "#f00",
                    height: 50,
                    marginVertical: 50,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                  }}
                  onPress={finishSeller}
                >
                  <Text style={styles.buttonText}>Seller Hinzufügen</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </View>
      )}
      {/* Show Categories */}
      {viewState === 3 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                width: width,
                marginTop: 45,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => setViewState(2)}
                style={{
                  width: width / 3,
                  height: 35,
                  backgroundColor: "#3a3a3a90",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                >
                  back
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 20, fontSize: 17, fontWeight: "600" }}>
                Kategorien
              </Text>
            </View>
            <View
              style={{
                width: width / 1.1,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {categories.map((category) => {
                return (
                  <View
                    key={category.name}
                    style={{
                      width: width / 1.2,
                      height: 45,
                      backgroundColor: category.color,
                      borderRadius: 25,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "600",
                        marginLeft: 15,
                      }}
                    >
                      {category.name} ///{" "}
                    </Text>
                    <Text
                      style={{ color: "#fff", fontSize: 16, fontWeight: "600" }}
                    >
                      mit {category.items.length} Items
                    </Text>
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        right: 15,
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        backgroundColor: "#f00",
                        alignItems: "center",
                      }}
                      onPress={() => {}}
                    >
                      <Text style={{ color: "#fff", fontSize: 24 }}>-</Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View>
        </ScrollView>
      )}
      {/* Show Items */}
      {viewState === 4 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                width: width,
                marginTop: 45,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => setViewState(2)}
                style={{
                  width: width / 3,
                  height: 35,
                  backgroundColor: "#3a3a3a90",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                >
                  back
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 20, fontSize: 17, fontWeight: "600" }}>
                Kategorie: {categoryName}
              </Text>
            </View>
            <View
              style={{
                width: width / 1.1,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {items.map((item, index) => {
                return (
                  <View
                    key={item.name}
                    style={{
                      width: width / 1.2,
                      height: 45,
                      backgroundColor: "#3a3a3a",
                      borderRadius: 25,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "600",
                        marginLeft: 15,
                      }}
                    >
                      {item.name} // {item.price}
                    </Text>
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        right: 15,
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        backgroundColor: "#f00",
                        alignItems: "center",
                      }}
                      onPress={() => deleteItem(index)}
                    >
                      <Text style={{ color: "#fff", fontSize: 24 }}>-</Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View>
        </ScrollView>
      )}
      {/* Show Variations */}
      {viewState === 5 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                width: width,
                marginTop: 45,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => setViewState(2)}
                style={{
                  width: width / 3,
                  height: 35,
                  backgroundColor: "#3a3a3a90",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                >
                  back
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 20, fontSize: 17, fontWeight: "600" }}>
                Item {itemName}
              </Text>
            </View>
            <View
              style={{
                width: width / 1.1,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {variations.map((variation) => {
                return (
                  <View
                    key={variation.name}
                    style={{
                      width: width / 1.2,
                      height: 45,
                      backgroundColor: "#3a3a3a",
                      borderRadius: 25,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "600",
                        marginLeft: 15,
                      }}
                    >
                      {variation.name} //{" "}
                      {variation.price ? variation.price : "No Price"}
                    </Text>
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        right: 15,
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        backgroundColor: "#f00",
                        alignItems: "center",
                      }}
                      onPress={() => {}}
                    >
                      <Text style={{ color: "#fff", fontSize: 24 }}>-</Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View>
        </ScrollView>
      )}
      {/* Edit Item */}
      {viewState === 6 && (
        <ScrollView style={{ width: width, flex: 1 }}>
          <View style={{ width: width, flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                width: width,
                marginTop: 45,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => setViewState(2)}
                style={{
                  width: width / 3,
                  height: 35,
                  backgroundColor: "#3a3a3a90",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 16, fontWeight: "500" }}
                >
                  back
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 20, fontSize: 17, fontWeight: "600" }}>
                Item bearbeiten
              </Text>
            </View>
            <View
              style={{
                width: width / 1.1,
                alignItems: "center",
                marginTop: 15,
              }}
            >
              {/* item preview */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: 15,
                  backgroundColor: "#ddd",
                  borderRadius: 15,
                }}
              >
                <View>
                  <Text
                    style={{
                      marginBottom: 3,
                      fontSize: 22,
                      color: "#3a3a3a",
                      fontWeight: "500",
                    }}
                  >
                    {itemToEdit.name}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 3,
                      fontSize: 18,
                      color: "#3a3a3a",
                    }}
                  >
                    {itemToEdit.desc}
                  </Text>
                  {itemToEdit.duration && <Text>{itemToEdit.duration}</Text>}
                  {itemToEdit.price && (
                    <Text
                      style={{
                        fontSize: 24,
                        color: "#3a3a3a",
                        fontWeight: "600",
                      }}
                    >
                      {itemToEdit.price} €
                    </Text>
                  )}
                </View>
                <Image
                  source={{ uri: itemToEdit.image }}
                  resizeMode="cover"
                  style={{
                    width: 150,
                    height: "100%",
                    borderRadius: 20,
                    backgroundColor: "#ccc",
                  }}
                />
              </View>
              {/* item input fields */}
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                    marginTop: 25,
                  },
                ]}
                value={itemToEdit.name}
                placeholder="Item Name:"
                onChangeText={(text) =>
                  setItemToEdit({ ...itemToEdit, name: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={itemToEdit.desc}
                placeholder="Item Beschreibung:"
                onChangeText={(text) =>
                  setItemToEdit({ ...itemToEdit, desc: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={itemToEdit.price}
                placeholder="Item Preis:"
                onChangeText={(text) =>
                  setItemToEdit({ ...itemToEdit, price: text })
                }
                keyboardType="numbers-and-punctuation"
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={itemToEdit.image}
                placeholder="Bild Quelle eingeben"
                onChangeText={(text) =>
                  setItemToEdit({ ...itemToEdit, image: text })
                }
                autoCorrect={false}
              />
              <TextInput
                style={[
                  styles.inputContainer,
                  {
                    borderColor: "#3a3a3a",
                  },
                ]}
                value={itemToEdit.duration}
                placeholder="Item Dauer bsp: 30 min / 1 std"
                onChangeText={(text) =>
                  setItemToEdit({ ...itemToEdit, duration: text })
                }
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => editItem()}
                style={{
                  width: width / 3,
                  height: 80,
                  backgroundColor: "#f00",
                  marginTop: 35,
                  borderRadius: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 26, fontWeight: "500" }}
                >
                  Speichern
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      )}
      {/* Logout Button */}
      <TouchableOpacity
        onPress={() => handleSignOut()}
        style={{
          position: "absolute",
          zIndex: 3,
          top: 10,
          right: 15,
          height: 35,
          width: 150,
          borderRadius: 15,
          backgroundColor: "#f00",
          alignItems: "center",
        }}
      >
        <Text style={{ color: "#fff", fontSize: 24 }}>Abmelden</Text>
      </TouchableOpacity>
    </View>
  );
};

export default AddSeller;

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#333",
    width: "90%",
    padding: 10,
    borderRadius: 5,
  },

  headerTitle: {
    fontSize: 20,
    color: "white",
  },

  form: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 50,
  },

  inputContainer: {
    width: "90%",
    padding: 13,
    marginVertical: 6,
    borderWidth: 2,
    borderColor: "#bbbbbb",
    borderRadius: 10,
  },

  buttonStyle: {
    backgroundColor: "red",
    borderRadius: 100,
    padding: 15,
    margin: 10,
  },

  disabledButtonStyle: {
    backgroundColor: "gray",
    borderRadius: 7,
    padding: 15,
    margin: 10,
  },

  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
  },
});
