export const colors = {
  red: "#DF2839",
  green: "#008f81",
  lightGray: "#eee",
  gray: "#DADADA",
  darkGray: "#4a4a4a",
  darkestGray: "#3A3A3A",
  white: "#fff",
  black: "#000",
  creme: "#FDF8F2",
};
