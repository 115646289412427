import React, { useState, useEffect } from "react";
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
// Screen
import AddStampCard from "./AddStampCard";
import Screen, { safeArea } from "../helpers/Screen";
import { ScrollView } from "react-native-gesture-handler";
// Firebase
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { constants } from "../helpers/constants";

const WIDTH = Screen.width;

const AssignStampcard = () => {
  // ViewStates
  const [viewState, setViewState] = useState(0);

  // State Variables
  const masters = [
    { name: "Abdi", uid: "bQCmuzSVEugRZ1Ch7wr4xdxeFDD3" },
    { name: "Aaron", uid: "vnueaOjlskZILnhYc8DqzkyWJdA3" },
    { name: "Alpi", uid: "J5ji13L2ynSJOme9CMNGp7V6Sxj2" },
    { name: "Daniel", uid: "cmASrZf2ZqY6dJ50xmAj17NEHxs2" },
    { name: "Nici", uid: "A8TTlqzrxQbqTC0VzzKSpeleohu2" },
    { name: "Valdet", uid: "zamvNfTpO4aDMBHM4Bgtu60U95y1" },
  ];
  const [currentMaster, setCurrentMaster] = useState(null);
  const [currentMasterCards, setCurrentMasterCards] = useState(null);
  const [sellers, setSellers] = useState(null);
  const [allStampcards, setAllStampcards] = useState([]);
  const [stampcardToEdit, setStampcardToEdit] = useState(null);
  const [masterStampcardToEdit, setMasterStampcardToEdit] = useState(null);

  // Functions
  useEffect(
    () =>
      onSnapshot(collection(db, "stampcard"), (snapshot) => {
        setAllStampcards(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }),
    []
  );

  useEffect(() => {
    if (currentMaster === null) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "user", currentMaster.uid, "stampcards"),
      (snapshot) => {
        setCurrentMasterCards(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }
    );
    return () => {
      unsubscribe();
    };
  }, [currentMaster]);

  useEffect(() => {
    const fetchSellers = async () => {
      const sellerRef = collection(db, constants.seller);

      try {
        const querySnapshot = await getDocs(sellerRef);
        const sellersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSellers(sellersData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSellers();
  }, []);

  const deleteStampcard = async (docId) => {
    try {
      const docRef = doc(db, "stampcard", docId);
      await deleteDoc(docRef);
      console.log("stampcard erfolgreich gelöscht!");
    } catch (error) {
      console.error("Fehler beim Löschen des stampcards:", error);
    }
  };

  const deleteMasterCard = async (docId) => {
    try {
      const docRef = doc(db, "user", currentMaster.uid, "stampcards", docId);
      await deleteDoc(docRef);
      console.log("stampcard erfolgreich gelöscht!");
    } catch (error) {
      console.error("Fehler beim Löschen des stampcards:", error);
    }
  };

  const addMasterCard = async (docId) => {
    const docRef = doc(db, "user", currentMaster.uid, "stampcards", docId);
    try {
      await setDoc(docRef, {
        inPropertySince: "",
        value: 0,
        level: 1,
        isFull: false,
      });
      console.log("Karte erfolgreich erstellt.");
    } catch (error) {
      console.error("Fehler beim Erstellen der Karte:", error);
    }
  };

  const resetMasterCard = async (docId) => {
    const docRef = doc(db, "user", currentMaster.uid, "stampcards", docId);
    try {
      await updateDoc(docRef, {
        value: 0,
        level: 1,
        isFull: false,
      });
      console.log("Karte erfolgreich zurückgesetzt.");
    } catch (error) {
      console.error("Fehler beim zurücksetzen der Karte:", error);
    }
  };

  const updateStampcard = async (docId) => {
    const updatedCard = {
      name: stampcardToEdit.name,
      adress: stampcardToEdit.adress,
      favicon: stampcardToEdit.favicon,
      image: stampcardToEdit.image,
      sellerID: stampcardToEdit.sellerID,
      color: stampcardToEdit.color,
    };
    try {
      const docRef = doc(db, "stampcard", docId);
      await updateDoc(docRef, updatedCard);
      console.log("Stampcard erfolgreich aktualisiert!");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Stampcards:", error);
    }
  };

  return (
    <>
      {/* Main Screen */}
      {viewState === 0 && (
        <View
          style={[safeArea.AndroidAndIOSSafeArea, { flex: 1, width: WIDTH }]}
        >
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: WIDTH,
                height: 50,
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "600", marginLeft: 10 }}>
                Stempelkarten
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setViewState(1);
                }}
                style={{
                  backgroundColor: "#f00",
                  paddingHorizontal: 12,
                  paddingVertical: 7,
                  borderRadius: 30,
                  marginRight: 10,
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 14, fontWeight: "600" }}
                >
                  Hinzufügen
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 10,
                fontWeight: "500",
                fontSize: 15,
                marginVertical: 15,
              }}
            >
              Master:
            </Text>
            <View style={{ paddingHorizontal: 10, alignItems: "center" }}>
              {masters.map((master) => {
                return (
                  <TouchableOpacity
                    key={master.uid}
                    style={{
                      width: WIDTH - 20,
                      height: 50,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#ccc",
                      marginBottom: 15,
                      borderRadius: 15,
                    }}
                    onPress={() => {
                      setCurrentMaster(master);
                      setViewState(3);
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: 15,
                        fontWeight: "600",
                        fontSize: 18,
                      }}
                    >
                      {master.name}
                    </Text>
                    <Text
                      style={{
                        marginRight: 15,
                        fontWeight: "300",
                        fontSize: 10,
                      }}
                    >
                      {master.uid}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
            <Text
              style={{
                marginLeft: 10,
                fontWeight: "500",
                fontSize: 15,
                marginVertical: 15,
              }}
            >
              Alle Stempelkarten:
            </Text>
            {allStampcards.length > 0 && (
              <View style={{ paddingHorizontal: 10, alignItems: "center" }}>
                {allStampcards.map((card) => {
                  return (
                    <TouchableOpacity
                      key={card.id}
                      style={{
                        width: WIDTH - 20,
                        height: 70,
                        marginBottom: 15,
                      }}
                      onPress={() => {
                        setStampcardToEdit(card);
                        setViewState(2);
                      }}
                    >
                      <Image
                        source={{ uri: card.image }}
                        resizeMode="cover"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 15,
                        }}
                      />
                      <View
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          borderRadius: 15,
                          backgroundColor: `${card.color}bb`,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={{ uri: card.favicon }}
                          resizeMode="cover"
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginLeft: 10,
                            backgroundColor: "#fff",
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 18,
                            fontWeight: "600",
                            color: "#fff",
                            marginLeft: 10,
                          }}
                        >
                          {card.name}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </ScrollView>
        </View>
      )}
      {/* Add Stampcard */}
      {viewState === 1 && (
        <AddStampCard
          goBack={() => {
            setViewState(0);
          }}
          sellers={sellers}
        />
      )}
      {/* Edit Stampcard */}
      {viewState === 2 && (
        <View
          style={[safeArea.AndroidAndIOSSafeArea, { flex: 1, width: WIDTH }]}
        >
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: WIDTH,
                height: 50,
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "600", marginLeft: 10 }}>
                {stampcardToEdit.name}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setViewState(0);
                }}
                style={{
                  backgroundColor: "#555",
                  paddingHorizontal: 12,
                  paddingVertical: 7,
                  borderRadius: 30,
                  marginRight: 10,
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 14, fontWeight: "600" }}
                >
                  Zurück
                </Text>
              </TouchableOpacity>
            </View>
            {stampcardToEdit !== null && (
              <View style={{ width: WIDTH, alignItems: "center" }}>
                <Text
                  style={{ width: "90%", fontWeight: "600", marginTop: 10 }}
                >
                  Stempelkarte bearbeiten:
                </Text>
                <TextInput
                  style={{
                    width: "90%",
                    padding: 13,
                    marginVertical: 6,
                    borderWidth: 2,
                    borderColor: "#bbbbbb",
                    borderRadius: 10,
                  }}
                  value={stampcardToEdit.name}
                  placeholder="Name:"
                  onChangeText={(text) =>
                    setStampcardToEdit({ ...stampcardToEdit, name: text })
                  }
                  autoCorrect={false}
                  autoCapitalize={"sentences"}
                />
                <TextInput
                  style={{
                    width: "90%",
                    padding: 13,
                    marginVertical: 6,
                    borderWidth: 2,
                    borderColor: "#bbbbbb",
                    borderRadius: 10,
                  }}
                  value={stampcardToEdit.adress}
                  placeholder="Adresse:"
                  onChangeText={(text) =>
                    setStampcardToEdit({ ...stampcardToEdit, adress: text })
                  }
                  autoCorrect={false}
                  autoCapitalize={"sentences"}
                />
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "600",
                      marginRight: 15,
                    }}
                  >
                    Logo -
                  </Text>
                  <Image
                    source={{ uri: stampcardToEdit.favicon }}
                    resizeMode="cover"
                    style={{
                      width: WIDTH / 5,
                      height: WIDTH / 5,
                      borderRadius: WIDTH / 5,
                    }}
                  />
                </View>
                <TextInput
                  style={{
                    width: "90%",
                    padding: 13,
                    marginVertical: 6,
                    borderWidth: 2,
                    borderColor: "#bbbbbb",
                    borderRadius: 10,
                  }}
                  value={stampcardToEdit.favicon}
                  placeholder="Logo:"
                  onChangeText={(text) =>
                    setStampcardToEdit({ ...stampcardToEdit, favicon: text })
                  }
                  autoCorrect={false}
                />
                <Text
                  style={{
                    width: "90%",
                    fontWeight: "600",
                    marginVertical: 5,
                  }}
                >
                  Hintergrundbild:
                </Text>
                <Image
                  source={{ uri: stampcardToEdit.image }}
                  resizeMode="cover"
                  style={{
                    width: "90%",
                    height: WIDTH / 4,
                    borderRadius: 15,
                  }}
                />
                <TextInput
                  style={{
                    width: "90%",
                    padding: 13,
                    marginVertical: 6,
                    borderWidth: 2,
                    borderColor: "#bbbbbb",
                    borderRadius: 10,
                  }}
                  value={stampcardToEdit.image}
                  placeholder="HintergrundBild:"
                  onChangeText={(text) =>
                    setStampcardToEdit({ ...stampcardToEdit, image: text })
                  }
                  autoCorrect={false}
                />
                <Text
                  style={{
                    width: "90%",
                    fontWeight: "600",
                    marginVertical: 5,
                  }}
                >
                  Seller:
                </Text>
                <Picker
                  selectedValue={stampcardToEdit.sellerID}
                  onValueChange={(itemValue) =>
                    setStampcardToEdit({
                      ...stampcardToEdit,
                      sellerID: itemValue,
                    })
                  }
                  style={{
                    marginBottom: 16,
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 8,
                    width: "90%",
                  }}
                >
                  <Picker.Item label="Select a seller ID" value="" />
                  {sellers &&
                    sellers.map((seller) => (
                      <Picker.Item
                        key={seller.id}
                        label={seller.name}
                        value={seller.id}
                      />
                    ))}
                </Picker>
                <Text
                  style={{
                    width: "90%",
                    fontWeight: "600",
                    marginVertical: 5,
                  }}
                >
                  Farbe:
                </Text>
                <TextInput
                  style={{
                    width: "90%",
                    padding: 13,
                    marginVertical: 6,
                    borderWidth: 2,
                    borderColor: "#bbbbbb",
                    borderRadius: 10,
                  }}
                  value={stampcardToEdit.color}
                  placeholder="Farbe: Beisp.(#123ABC)"
                  onChangeText={(text) =>
                    setStampcardToEdit({ ...stampcardToEdit, color: text })
                  }
                  autoCorrect={false}
                />
                <TouchableOpacity
                  style={{
                    width: "90%",
                    height: 60,
                    backgroundColor: stampcardToEdit.color,
                    borderRadius: 30,
                    marginTop: 35,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    updateStampcard(stampcardToEdit.id);
                    setViewState(0);
                  }}
                >
                  <Text
                    style={{ fontSize: 22, fontWeight: "500", color: "#fff" }}
                  >
                    Update Infos
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    width: "80%",
                    height: 40,
                    backgroundColor: "red",
                    borderRadius: 30,
                    marginTop: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    deleteStampcard(stampcardToEdit.id);
                    setViewState(0);
                  }}
                >
                  <Text
                    style={{ fontSize: 20, fontWeight: "500", color: "#fff" }}
                  >
                    Löschen
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </ScrollView>
        </View>
      )}
      {/* Edit Master Stampcards */}
      {viewState === 3 && (
        <View
          style={[safeArea.AndroidAndIOSSafeArea, { flex: 1, width: WIDTH }]}
        >
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: WIDTH,
                height: 50,
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "600", marginLeft: 10 }}>
                {currentMaster.name}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setViewState(0);
                }}
                style={{
                  backgroundColor: "#555",
                  paddingHorizontal: 12,
                  paddingVertical: 7,
                  borderRadius: 30,
                  marginRight: 10,
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 14, fontWeight: "600" }}
                >
                  Zurück
                </Text>
              </TouchableOpacity>
            </View>
            {currentMasterCards !== null && (
              <View style={{ width: WIDTH, alignItems: "center" }}>
                <Text
                  style={{
                    width: "92%",
                    marginTop: 5,
                    marginBottom: 15,
                    fontWeight: "500",
                    fontSize: 16,
                  }}
                >
                  Deine Stempelkarten:
                </Text>
                {allStampcards.map((card) => {
                  return (
                    <View key={card.id}>
                      {currentMasterCards.map((masterCard) => {
                        if (card.id === masterCard.id) {
                          return (
                            <TouchableOpacity
                              key={masterCard.id}
                              style={{
                                width: WIDTH - 20,
                                height: 70,
                                marginBottom: 15,
                              }}
                              onPress={() => {
                                setMasterStampcardToEdit({
                                  id: card.id,
                                  value: masterCard.value,
                                  level: masterCard.level,
                                  isFull: masterCard.isFull,
                                  name: card.name,
                                  color: card.color,
                                  favicon: card.favicon,
                                });
                                setViewState(4);
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  position: "absolute",
                                  zIndex: 2,
                                  width: 25,
                                  height: 25,
                                  backgroundColor: "#f00",
                                  borderRadius: 15,
                                  alignItems: "center",
                                  top: -10,
                                  left: -7,
                                }}
                                onPress={() => {
                                  deleteMasterCard(card.id);
                                }}
                              >
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 18,
                                    fontWeight: "600",
                                  }}
                                >
                                  -
                                </Text>
                              </TouchableOpacity>
                              <Image
                                source={{ uri: card.image }}
                                resizeMode="cover"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 15,
                                }}
                              />
                              <View
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 15,
                                  backgroundColor: `${card.color}bb`,
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Image
                                  source={{ uri: card.favicon }}
                                  resizeMode="cover"
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 25,
                                    marginLeft: 10,
                                    backgroundColor: "#fff",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontWeight: "600",
                                    color: "#fff",
                                    marginLeft: 10,
                                  }}
                                >
                                  {card.name}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          );
                        }
                      })}
                    </View>
                  );
                })}
                <Text
                  style={{
                    width: "92%",
                    marginTop: 5,
                    marginBottom: 15,
                    fontWeight: "500",
                    fontSize: 16,
                  }}
                >
                  Andere Stempelkarten:
                </Text>
                {allStampcards.map((card) => {
                  return (
                    <TouchableOpacity
                      key={card.id}
                      style={{
                        width: WIDTH - 20,
                        height: 70,
                        marginBottom: 15,
                      }}
                      onPress={() => {
                        addMasterCard(card.id);
                      }}
                    >
                      <Image
                        source={{ uri: card.image }}
                        resizeMode="cover"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 15,
                        }}
                      />
                      <View
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          borderRadius: 15,
                          backgroundColor: `${card.color}bb`,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={{ uri: card.favicon }}
                          resizeMode="cover"
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginLeft: 10,
                            backgroundColor: "#fff",
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 18,
                            fontWeight: "600",
                            color: "#fff",
                            marginLeft: 10,
                          }}
                        >
                          {card.name}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}
                {currentMasterCards.map((mc) => {
                  return (
                    <View
                      key={mc.id}
                      style={{
                        width: "90%",
                        height: 50,
                        backgroundColor: "#555",
                        borderRadius: 20,
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 15,
                        justifyContent: "space-between",
                        paddingHorizontal: 15,
                      }}
                    >
                      <Text style={{ color: "#fff", fontSize: 10 }}>
                        {mc.id}
                      </Text>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={{ color: "#fff" }}>
                          S:{mc.value}/L:{mc.level}
                        </Text>
                        <TouchableOpacity
                          style={{
                            width: 25,
                            height: 25,
                            backgroundColor: "#f00",
                            borderRadius: 15,
                            alignItems: "center",
                            marginLeft: 15,
                          }}
                          onPress={() => {
                            deleteMasterCard(mc.id);
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: 18,
                              fontWeight: "600",
                            }}
                          >
                            -
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                })}
              </View>
            )}
          </ScrollView>
        </View>
      )}
      {/* Detail Master Stampcard */}
      {viewState === 4 && (
        <View
          style={[safeArea.AndroidAndIOSSafeArea, { flex: 1, width: WIDTH }]}
        >
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: WIDTH,
                height: 50,
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "600", marginLeft: 10 }}>
                {masterStampcardToEdit.name}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setViewState(3);
                }}
                style={{
                  backgroundColor: "#555",
                  paddingHorizontal: 12,
                  paddingVertical: 7,
                  borderRadius: 30,
                  marginRight: 10,
                }}
              >
                <Text
                  style={{ color: "#fff", fontSize: 14, fontWeight: "600" }}
                >
                  Zurück
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                width: WIDTH,
                height: WIDTH,
                backgroundColor: "#ccc",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ alignItems: "center" }}>
                <Image
                  source={{ uri: masterStampcardToEdit.favicon }}
                  resizeMode="cover"
                  style={{
                    width: WIDTH / 4,
                    height: WIDTH / 4,
                    borderRadius: WIDTH / 4,
                    marginTop: 20,
                  }}
                />
                <Text
                  style={{ marginTop: 15, fontSize: 20, fontWeight: "600" }}
                >
                  Stempel: {masterStampcardToEdit.value}
                </Text>
                <Text
                  style={{ marginTop: 10, fontSize: 20, fontWeight: "600" }}
                >
                  Level: {masterStampcardToEdit.level}
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  width: "70%",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 55,
                  backgroundColor: masterStampcardToEdit.color,
                  borderRadius: 30,
                  marginBottom: 25,
                }}
                onPress={() => {
                  resetMasterCard(masterStampcardToEdit.id);
                  setViewState(3);
                }}
              >
                <Text
                  style={{ fontSize: 18, fontWeight: "600", color: "#fff" }}
                >
                  Reset Card
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default AssignStampcard;

const styles = StyleSheet.create({});
