import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRm2talzxjVZWP_SXoblKw7GBaavxW5ds",
  authDomain: "stampit-d55bb.firebaseapp.com",
  databaseURL: "https://stampit-d55bb.firebaseio.com",
  projectId: "stampit-d55bb",
  storageBucket: "stampit-d55bb.appspot.com",
  messagingSenderId: "526568213736",
  appId: "1:526568213736:web:d817d8ab3beda83649cd5d",
  measurementId: "G-GEZSRY8B72",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

export { app, auth, db };
