import { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
} from "react-native";
import { cardStyles } from "./StampcardStyles";
import { colors } from "../../helpers/colors";
import Screen from "../../helpers/Screen";

const Stampcard = ({ name, address, image, favIcon, color }) => {
  var upperStamps = [];
  var lowerStamps = [];

  // Stamps Section
  if (color) {
    for (let i = 0; i < 5; i++) {
      if (i < 5) {
        upperStamps.push(
          <View key={i} style={{ height: 40, width: 40 }}>
            <Image
              source={require("../../assets/icons/stampback.png")}
              resizeMode="contain"
              style={{ height: 40, width: 40, tintColor: color }}
            />
            <Image
              source={require("../../assets/icons/stamp.png")}
              resizeMode="contain"
              style={cardStyles.stampImage}
            />
          </View>
        );
      } else {
        upperStamps.push(
          <Image
            key={i}
            source={require("../../assets/icons/emptystamp.png")}
            resizeMode="contain"
            style={cardStyles.stampSize}
          />
        );
      }
    }
    for (let i = 5; i < 10; i++) {
      if (i < 5) {
        lowerStamps.push(
          <View key={i} style={cardStyles.stampSize}>
            <Image
              source={require("../../assets/icons/stampback.png")}
              resizeMode="contain"
              style={[cardStyles.stampBack, { tintColor: color }]}
            />
            <Image
              source={require("../../assets/icons/stamp.png")}
              resizeMode="contain"
              style={cardStyles.stampImage}
            />
          </View>
        );
      } else {
        lowerStamps.push(
          <Image
            key={i}
            source={require("../../assets/icons/emptystamp.png")}
            resizeMode="contain"
            style={cardStyles.stampSize}
          />
        );
      }
    }
  }
  return (
    <View style={cardStyles.shadow}>
      {/* Normal Stampcard */}
      <ImageBackground
        source={{
          uri:
            image !== ""
              ? image
              : "https://images.pexels.com/photos/1813272/pexels-photo-1813272.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        }}
        resizeMode="cover"
        borderRadius={15}
        style={cardStyles.stampCardView}
        /*         isBackground */
      >
        <TouchableOpacity
          style={cardStyles.stampCardViewDetails}
          activeOpacity={0.9}
          /*           onPress={() => {
            haptic("normal");
            navigation.push("SellerDetails", stampCardInfo.sellerID);
          }} */
        >
          {/* Seller Favicon, adress and info button */}
          <View style={cardStyles.stampBodyContainer}>
            <View style={{ flexDirection: "row" }}>
              <Image
                source={{
                  uri:
                    favIcon !== ""
                      ? favIcon
                      : "https://img.freepik.com/premium-vector/barber-shop-vintage-logo-design-illustration_441059-11.jpg?w=2000",
                }}
                resizeMode="cover"
                style={cardStyles.favicon}
              />
              <View style={cardStyles.infoContainer}>
                <Text style={{ color: "#3a3a3a" }}>{name}</Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 3,
                    /* fontFamily: "Worksans", */
                    color: "#3a3a3a",
                  }}
                >
                  {address}
                </Text>
              </View>
            </View>
            {/* Show All Deals Button */}
            <TouchableOpacity
              color={"#008f8150"}
              width={Screen.width / 5}
              height={Screen.width / 12}
              /* onPress={() => {
                  getAllDeals(stampCardInfo.sellerID, userStampcardData.level);
                }} */
            >
              <View
                style={{
                  width: Screen.width / 5 - 6,
                  height: Screen.width / 12 - 5,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.green,
                  borderRadius: Screen.width / 12,
                }}
              >
                <Text style={cardStyles.showAllDealsButtonText}>Deals</Text>
              </View>
            </TouchableOpacity>
          </View>
          {/* Stamps */}
          <View style={{ marginTop: 5, paddingHorizontal: 20 }}>
            <View style={[cardStyles.stampAreaContainer, { marginTop: 5 }]}>
              {upperStamps}
            </View>
            <View style={cardStyles.stampAreaContainer}>{lowerStamps}</View>
          </View>
          {/* Level Indicator */}
          <View style={cardStyles.levelContainer}>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Image
                source={require("../../assets/icons/levelindicatorfront.png")}
                resizeMode="stretch"
                style={{
                  width: 16,
                  height: 22,
                  tintColor: color,
                }}
              />
              <Text style={cardStyles.levelText}>1</Text>
            </View>
            <View>
              <Image
                source={require("../../assets/icons/levelindicatorback.png")}
                resizeMode="stretch"
                style={{ width: 4, height: 5, tintColor: "#000" }}
              />
              <Image
                source={require("../../assets/icons/levelindicatorback.png")}
                resizeMode="stretch"
                style={{
                  width: 4,
                  height: 5,
                  position: "absolute",
                  tintColor: `${color}90`,
                }}
              />
            </View>
          </View>
        </TouchableOpacity>
      </ImageBackground>
    </View>
  );
};

export default Stampcard;
